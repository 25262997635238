import React, { Suspense, useState } from 'react';
import Loader from './Loader';
import {
  Button,
  PageHeader,
  Row,
  Table,
  Col,
  Modal,
  Card,
  Divider,
  message,
  Upload,
  Spin,
} from 'antd';
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage,
  StorageImage,
  useStorageTask,
} from 'reactfire';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const Banners = (props) => {
  const firestore = useFirestore();
  const getBanners = useFirestore().collection('banners');
  const { status: bannersStatus, data: banners } =
    useFirestoreCollectionData(getBanners);

  const [uploading, setUploading] = useState(false);
  const [ref, setRef] = useState(undefined);
  const storage = useStorage();

  console.log('log-banners', banners);

  return (
    <Suspense fallback={<Loader />}>
      <PageHeader></PageHeader>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Table
            loading={bannersStatus === 'loading'}
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                render: (text) => {
                  return (
                    <img style={{ width: 100, objectFit: 'fill' }} src={text} />
                  );
                },
              },
              {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => {
                  return (
                    <Button.Group size='small'>
                      <Button
                        type='danger'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          firestore
                            .collection('banners')
                            .doc(record.NO_ID_FIELD)
                            .delete()
                            .then((r) => {
                              Modal.success({
                                title: 'Success!',
                                content: 'Banner has been deleted.',
                              });
                            });
                        }}
                      />
                    </Button.Group>
                  );
                },
              },
            ]}
            dataSource={banners}
            rowKey='NO_ID_FIELD'
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Spin spinning={uploading}>
            <input
              type='file'
              name='file'
              onChange={(event) => {
                setUploading(true);
                const fileToUpload = event.target.files[0];
                const fileName = fileToUpload.name;
                const newRef = storage.ref('banners').child(fileName);
                setRef(newRef);
                const uploadTask = newRef.put(fileToUpload);

                uploadTask.on(
                  'state_changed',
                  function (snapshot) {
                    let progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  },
                  function (error) {
                    Modal.error({
                      title: 'Sorry',
                      content: error,
                    });
                    setUploading(false);
                    // Handle unsuccessful uploads
                  },
                  function () {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then(function (downloadURL) {
                        firestore
                          .collection('banners')
                          .add({ name: fileName, image: downloadURL })
                          .then((r) => {
                            setUploading(false);
                          });
                      });
                  }
                );
              }}
            />
          </Spin>
        </Col>
      </Row>
    </Suspense>
  );
};

export default Banners;
