import React, { Suspense, useRef, useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Loader from './Loader';
import {
  Card,
  Divider,
  Input,
  Row,
  Col,
  Table,
  Tag,
  Button,
  Modal,
  Space,
  Form,
  Switch,
  InputNumber,
  Select,
  List,
} from 'antd';
import moment from 'moment';
import {
  CloseOutlined,
  DeleteOutlined,
  RollbackOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const ActiveOrders = (props) => {
  const [form] = Form.useForm();
  const firestore = useFirestore();
  const getUsers = useFirestore().collection('users');
  const { status: usersStatus, data: users } =
    useFirestoreCollectionData(getUsers);

  const getOrders = useFirestore().collection('orders');
  const { status: orderStatus, data: orders } =
    useFirestoreCollectionData(getOrders);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [single, setSingle] = useState(false);
  const [loading, setLoading] = useState(false);

  const config = {
    key_column: 'NO_ID_FIELD',
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'basic',
    button: {
      excel: false,
      print: false,
    },
    language: {
      loading_text: 'Please wait...',
    },
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const randomString = (length = 20) => {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  return (
    <Suspense fallback={<Loader />}>
      <Table
        loading={orderStatus === 'loading'}
        columns={[
          {
            title: 'Id',
            dataIndex: 'orderId',
            key: 'orderId',
            ...getColumnSearchProps('orderId'),
          },
          {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            ellipsis: true,
            ...getColumnSearchProps('customer'),
          },
          {
            title: 'Freelancer',
            dataIndex: 'freelancer',
            key: 'freelancer',
            responsive: ['md'],
            ...getColumnSearchProps('freelancer'),
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            responsive: ['md'],
            render: (text) => moment(text).format('LLL'),
            sorter: {
              compare: (a, b) =>
                moment(a.createdAt).unix() - moment(b.createdAt).unix(),
            },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            responsive: ['md'],
            render: (text) => {
              if (text === 'accepted') {
                return <Tag color='#87d068'>{text}</Tag>;
              } else if (text === 'looking') {
                return <Tag color='#108ee9'>{text}</Tag>;
              } else if (text === 'canceled') {
                return <Tag color='#f50'>{text}</Tag>;
              } else if (text === 'upcoming') {
                return <Tag color='#1d39c4'>{text}</Tag>;
              } else {
                return <Tag color='#87d068'>{text}</Tag>;
              }
            },
          },
          {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            responsive: ['md'],
            render: (text) => `${text} CAD`,
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
              return (
                <Button.Group size='small'>
                  {record.status === 'upcoming' && (
                    <Button
                      type='dashed'
                      icon={<CloseOutlined />}
                      onClick={() => {
                        firestore
                          .collection('orders')
                          .doc(record.NO_ID_FIELD)
                          .set(
                            {
                              status: 'canceled',
                            },
                            { merge: true }
                          )
                          .then((r) => {
                            Modal.success({
                              title: 'Success!',
                              content: 'Order status has been updated.',
                            });
                          });
                      }}
                    />
                  )}

                  <Button
                    type='danger'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure?',
                        content:
                          'You cannot retrieve this order once you deleted.',
                        okText: 'Yes',
                        onOk: () => {
                          firestore
                            .collection('orders')
                            .doc(record.NO_ID_FIELD)
                            .delete()
                            .then((r) => {
                              Modal.success({
                                title: 'Success!',
                                content: 'Order has been deleted.',
                              });
                            });
                        },
                      });
                    }}
                  />
                  {record.status !== 'canceled' && (
                    <Button
                      type='ghost'
                      icon={<RollbackOutlined />}
                      onClick={() => {
                        setIsModalVisible(true);
                        setSingle({
                          code: randomString(10),
                          customers: [record.orderedBy],
                          detail: 'Voucher',
                          forAll: false,
                          value: 0,
                          voucher: true,
                          total: record.total,
                          orderID: record.NO_ID_FIELD,
                        });
                        // code: "ABCDEFGFREE"
                        // customers: ["8LZRScEQvDO9dNgIHI84CZAf28O2"]
                        // detail: "Test"
                        // forAll: false
                        // value: 12
                        // voucher: true
                      }}
                    />
                  )}
                </Button.Group>
              );
            },
          },
        ]}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Card>
                <Divider style={{ marginBottom: 50 }}>
                  Order Information
                </Divider>
                <Row justify='center'>
                  <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        color: 'gray',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      - Order -
                    </h3>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                      <h4>
                        <div style={{ color: 'gray' }}>Order Date: </div>
                        {moment(
                          record.orderDate,
                          'MM-DD-YYYY HH:mm:ss a'
                        ).format('MM-DD-YYYY')}
                      </h4>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                      <h4 style={{ marginBottom: 20 }}>
                        <div style={{ color: 'gray' }}>Order Time: </div>
                        {moment(
                          record.orderDate,
                          'MM-DD-YYYY HH:mm:ss a'
                        ).format('hh:mm')}{' '}
                        -{' '}
                        {moment(record.orderDate, 'MM-DD-YYYY HH:mm:ss a')
                          .add(record.totalTime, 'minutes')
                          .format('hh:mm')}
                      </h4>
                    </Col>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        color: 'gray',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      - Items -
                    </h3>
                    {record.cart &&
                      record.cart.map((citem, cindex) => {
                        if (citem.type === 'main' || citem.type === 'another') {
                          return (
                            <div key={citem.id}>
                              <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                  <h3 style={{ fontWeight: 'bold' }}>
                                    {citem.name}
                                  </h3>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                  <h3 style={{ fontWeight: 'bold' }}>
                                    {citem.price + ' CAD'}
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })}

                    <Col lg={{ span: 24 }}>
                      <h4 style={{ fontWeight: 'bold' }}>Add-ons : </h4>
                    </Col>

                    {record.cart &&
                      record.cart.map((citem, cindex) => {
                        return (
                          <div key={citem.id}>
                            <Row>
                              <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                                {citem.type === 'popular' && citem.name}
                              </Col>
                              <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                                {citem.type === 'popular' &&
                                  citem.price + ' CAD'}
                              </Col>
                            </Row>
                          </div>
                        );
                      })}

                    <div>
                      {record.cart.type === 'popular' && record.cart.name}
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        color: 'gray',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      - Freelancer Info -
                    </h3>
                    {record.acceptedBy.length > 0 && record.freelancer ? (
                      <h4>Name: {record.freelancer}</h4>
                    ) : (
                      <h4>Order is not accepted.</h4>
                    )}
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <h3
                      style={{
                        fontWeight: 'bold',
                        color: 'gray',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      - Address -
                    </h3>
                    <Col lg={{ span: 24 }}>
                      <h4>Street: {record.street}</h4>
                      <h4>Unit/Flat: {record.unit ? record.unit : ''}</h4>
                      <h4>City: {record.city}</h4>
                      <h4>Province: {record.state}</h4>
                      <h4>Postal Code: {record.zip}</h4>
                      <h4>Country: {record.country}</h4>
                    </Col>
                  </Col>
                </Row>
              </Card>
            );
          },
        }}
        dataSource={orders.map((orderitem, orderindex) => {
          let customer = users.filter(
            (uitem) => uitem.NO_ID_FIELD === orderitem.orderedBy
          )[0];
          let freelancer =
            orders.acceptedBy !== 'none' &&
            users.filter(
              (uitem) => uitem.NO_ID_FIELD === orderitem.acceptedBy
            )[0];

          return {
            ...orderitem,
            customer: customer && customer.firstName + ' ' + customer.lastName,
            rowid: orderindex && orderindex,
            freelancer:
              freelancer &&
              freelancer.firstName &&
              freelancer.lastName &&
              freelancer.firstName + ' ' + freelancer.lastName,
          };
        })}
        rowKey='NO_ID_FIELD'
      />
      <Modal
        title={'Create Voucher'}
        visible={isModalVisible}
        closable={false}
        maskClosable={false}
        footer={null}
      >
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            code: single ? single.code : '',
            detail: single ? single.detail : '',
            value: single ? single.value : '',
            forAll: single ? single.forAll : false,
            customers: single ? single.customers : [],
          }}
          onFinish={(values) => {
            Modal.warning({
              title: 'Are you sure to refund this order?',
              content: `If you refund this order you can't undo this later.`,
              onOk: () => {
                setLoading(false);
                let orderID = single.orderID;
                let calculatedPercent = (single.total / 100) * values.value;
                let theVoucher = {
                  code: single.code,
                  customers: single.customers,
                  detail: 'Voucher',
                  forAll: false,
                  value: single.total - calculatedPercent,
                  voucher: true,
                };
                firestore
                  .collection('promos')
                  .add(theVoucher)
                  .then((r) => {
                    if (r.id) {
                      firestore
                        .collection('orders')
                        .doc(orderID)
                        .set(
                          {
                            status: 'canceled',
                          },
                          { merge: true }
                        )
                        .then((r) => {
                          setSingle(false);
                          setIsModalVisible(false);
                          Modal.success({
                            title: 'Success!',
                            content:
                              'Order status has been updated. You have created a refund for this order.',
                          });
                        });
                      form.resetFields();
                    } else {
                      Modal.error({
                        title: 'Sorry!',
                        content: 'Cannot create coupon.',
                      });
                    }
                    setLoading(false);
                  });
              },
              onCancel: () => {},
              okText: 'Continue',
            });
          }}
        >
          <Form.Item
            label='Discount Type'
            name='discountType'
            valuePropName='checked'
          >
            <Switch
              checkedChildren='Amount'
              unCheckedChildren='Percentage'
              onChange={(value) => {
                if (value) {
                  form.setFieldsValue({
                    value: single.total,
                  });
                } else {
                  form.setFieldsValue({
                    value: 100,
                  });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.discountType !== currentValues.discountType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('discountType') ? (
                <Form.Item
                  label='Value In amount'
                  help="Please choose the value type as 'Amount'."
                  name='value'
                  rules={[
                    {
                      required: true,
                      message: 'Please input value!',
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={single.total}
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder='Value'
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label='Value in percentage'
                  help="Please choose the value type as 'Percentage'."
                  name='value'
                  rules={[
                    {
                      required: true,
                      message: 'Please input value!',
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder='Value'
                  />
                </Form.Item>
              )
            }
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              loading={loading}
              disabled={loading}
            >
              Create Voucher
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              type='danger'
              block
              onClick={() => {
                setIsModalVisible(false);
                setSingle(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Suspense>
  );
};

export default ActiveOrders;
