import React from 'react';
import { Layout, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom';
const { Header, Footer, Content } = Layout;

const Terms = (props) => {
  return (
    <Layout>
      <Header className='header'>
        <Row justify='space-between'>
          <Col span={2}>
            <Link to='/'>Nailzi.com</Link>
          </Col>
          <Col span={1}>
            <div className='header-logo'>
              <Link to='/login'>
                <img src={require('../assets/logoWhite.png')} />
              </Link>
            </div>
          </Col>
        </Row>
      </Header>
      <Content className='home-content'>
        <Row
          className='service-container'
          align='top'
          style={{ marginTop: 40 }}
        >
          <Col lg={{ span: 20 }}>
            <Divider>
              <h3>Customer's Terms Of Service</h3>
            </Divider>
            <div className='home-card home-card-center freelancer-disc'>
              <p dir='ltr'>TERMS OF SERVICE</p>
              <br />
              <ol>
                <li dir='ltr'>
                  <p dir='ltr'>Introduction</p>
                </li>
              </ol>
              <p dir='ltr'>
                Welcome to Nailzi (“Company”, “we”, “our”, “us”)! As you have
                just clicked our Terms of Service, please pause, grab a cup of
                coffee and carefully read the following pages. It will take you
                approximately 20 minutes.
              </p>
              <p dir='ltr'>
                These Terms of Service (“Terms”, “Terms of Service”) govern your
                use of our web pages located at Nailzi.com and our mobile
                application Nailzi (together or individually “Service”) operated
                by Nailzi
              </p>
              <p dir='ltr'>
                Your agreement with us includes these Terms (“Agreements”). You
                acknowledge that you have read and understood Agreements, and
                agree to be bound of them.
              </p>
              <p dir='ltr'>
                If you do not agree with (or cannot comply with) Agreements,
                then you may not use the Service, but please let us know by
                emailing at Nailzi.services@gmail.com so we can try to find a
                solution. These Terms apply to all visitors, users and others
                who wish to access or use Service.
              </p>
              <p dir='ltr'>Thank you for being responsible.</p>
              <ol start='2'>
                <li dir='ltr'>
                  <p dir='ltr'>Communications</p>
                </li>
              </ol>
              <p dir='ltr'>
                By creating an Account on our Service, you agree to subscribe to
                newsletters, marketing or promotional materials and other
                information we may send. However, you may opt out of receiving
                any, or all, of these communications from us by following the
                unsubscribe link or by emailing at.
              </p>
              <ol start='3'>
                <li dir='ltr'>
                  <p dir='ltr'>Purchases</p>
                </li>
              </ol>
              <p dir='ltr'>
                If you wish to purchase any product or service made available
                through Service (“Purchase”), you may be asked to supply certain
                information relevant to your Purchase including, without
                limitation, your credit card number, the expiration date of your
                credit card, your billing address, and your shipping
                information.
              </p>
              <p dir='ltr'>
                You represent and warrant that: (i) you have the legal right to
                use any credit card(s) or other payment method(s) in connection
                with any Purchase; and that (ii) the information you supply to
                us is true, correct and complete.
              </p>
              <p dir='ltr'>
                We may employ the use of third party services for the purpose of
                facilitating payment and the completion of Purchases. By
                submitting your information, you grant us the right to provide
                the information to these third parties.
              </p>
              <p dir='ltr'>
                We reserve the right to refuse or cancel your order at any time
                for reasons including but not limited to: product or service
                availability, errors in the description or price of the product
                or service, error in your order or other reasons.
              </p>
              <p dir='ltr'>
                We reserve the right to refuse or cancel your order if fraud or
                an unauthorized or illegal transaction is suspected.
              </p>
              <ol start='4'>
                <li dir='ltr'>
                  <p dir='ltr'>Contests, Sweepstakes and Promotions</p>
                </li>
              </ol>
              <p dir='ltr'>
                Any contests, sweepstakes or other promotions (collectively,
                “Promotions”) made available through Service may be governed by
                rules that are separate from these Terms of Service. If you
                participate in any Promotions, please review the applicable
                rules. If the rules for a Promotion conflict with these Terms of
                Service, Promotion rules will apply.
              </p>
              <ol start='5'>
                <li dir='ltr'>
                  <p dir='ltr'>Fee Changes</p>
                </li>
              </ol>
              <p dir='ltr'>
                Nailzi, in its sole discretion and at any time, may modify
                Subscription fees for the Subscriptions. Any Subscription fee
                change will become effective at the end of the then-current
                Billing Cycle.
              </p>
              <p dir='ltr'>
                Nailzi will provide you with a reasonable prior notice of any
                change in Subscription fees to give you an opportunity to
                terminate your Subscription before such change becomes
                effective.
              </p>
              <p dir='ltr'>
                Your continued use of Service after Subscription fee change
                comes into effect constitutes your agreement to pay the modified
                Subscription fee amount.
              </p>
              <ol start='6'>
                <li dir='ltr'>
                  <p dir='ltr'>Refunds</p>
                </li>
              </ol>
              <p dir='ltr'>
                We issue refunds for Contracts within fifteen (15) days of the
                original purchase of the Contract.
              </p>
              <ol start='7'>
                <li dir='ltr'>
                  <p dir='ltr'>Content</p>
                </li>
              </ol>
              <p dir='ltr'>
                Our Service allows you to post, link, store, share and otherwise
                make available certain information, text, graphics, videos, or
                other material (“Content”). You are responsible for Content that
                you post on or through Service, including its legality,
                reliability, and appropriateness.
              </p>
              <p dir='ltr'>
                By posting Content on or through Service, You represent and
                warrant that: (i) Content is yours (you own it) and/or you have
                the right to use it and the right to grant us the rights and
                license as provided in these Terms, and (ii) that the posting of
                your Content on or through Service does not violate the privacy
                rights, publicity rights, copyrights, contract rights or any
                other rights of any person or entity. We reserve the right to
                terminate the account of anyone found to be infringing on a
                copyright.
              </p>
              <p dir='ltr'>
                You retain any and all of your rights to any Content you submit,
                post or display on or through Service and you are responsible
                for protecting those rights. We take no responsibility and
                assume no liability for Content you or any third party posts on
                or through Service. However, by posting Content using Service
                you grant us the right and license to use, modify, publicly
                perform, publicly display, reproduce, and distribute such
                Content on and through Service. You agree that this license
                includes the right for us to make your Content available to
                other users of Service, who may also use your Content subject to
                these Terms.
              </p>
              <p dir='ltr'>
                Nailzi has the right but not the obligation to monitor and edit
                all Content provided by users.
              </p>
              <p dir='ltr'>
                In addition, Content found on or through this Service are the
                property of Nailzi or used with permission. You may not
                distribute, modify, transmit, reuse, download, repost, copy, or
                use said Content, whether in whole or in part, for commercial
                purposes or for personal gain, without express advance written
                permission from us.
              </p>
              <ol start='8'>
                <li dir='ltr'>
                  <p dir='ltr'>Prohibited Uses</p>
                </li>
              </ol>
              <p dir='ltr'>
                You may use Service only for lawful purposes and in accordance
                with Terms. You agree not to use Service:
              </p>
              <ol>
                <li dir='ltr'>
                  <p dir='ltr'>
                    In any way that violates any applicable national or
                    international law or regulation.
                  </p>
                </li>
              </ol>
              <ol start='2'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content or otherwise.
                  </p>
                </li>
              </ol>
              <ol start='3'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain
                    letter,” “spam,” or any other similar solicitation.
                  </p>
                </li>
              </ol>
              <ol start='4'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    To impersonate or attempt to impersonate Company, a Company
                    employee, another user, or any other person or entity.
                  </p>
                </li>
              </ol>
              <ol start='5'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    In any way that infringes upon the rights of others, or in
                    any way is illegal, threatening, fraudulent, or harmful, or
                    in connection with any unlawful, illegal, fraudulent, or
                    harmful purpose or activity.
                  </p>
                </li>
              </ol>
              <ol start='6'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as
                    determined by us, may harm or offend Company or users of
                    Service or expose them to liability.
                  </p>
                </li>
              </ol>
              <p dir='ltr'>Additionally, you agree not to:</p>
              <ol>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Use Service in any manner that could disable, overburden,
                    damage, or impair Service or interfere with any other
                    party’s use of Service, including their ability to engage in
                    real time activities through Service.
                  </p>
                </li>
              </ol>
              <ol start='2'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Use any robot, spider, or other automatic device, process,
                    or means to access Service for any purpose, including
                    monitoring or copying any of the material on Service.
                  </p>
                </li>
              </ol>
              <ol start='3'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Use any manual process to monitor or copy any of the
                    material on Service or for any other unauthorized purpose
                    without our prior written consent.
                  </p>
                </li>
              </ol>
              <ol start='4'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Use any device, software, or routine that interferes with
                    the proper working of Service.
                  </p>
                </li>
              </ol>
              <ol start='5'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Introduce any viruses, trojan horses, worms, logic bombs, or
                    other material which is malicious or technologically
                    harmful.
                  </p>
                </li>
              </ol>
              <ol start='6'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of Service, the server on which
                    Service is stored, or any server, computer, or database
                    connected to Service.
                  </p>
                </li>
              </ol>
              <ol start='7'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Attack Service via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </p>
                </li>
              </ol>
              <ol start='8'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Take any action that may damage or falsify Company rating.
                  </p>
                </li>
              </ol>
              <ol start='9'>
                <li dir='ltr'>
                  <p dir='ltr'>
                    Otherwise attempt to interfere with the proper working of
                    Service.
                  </p>
                </li>
              </ol>
              <ol start='9'>
                <li dir='ltr'>
                  <p dir='ltr'>Analytics</p>
                </li>
              </ol>
              <p dir='ltr'>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <p dir='ltr'>Google Analytics</p>
              <p dir='ltr'>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>
              <p dir='ltr'>
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                <a href='https://policies.google.com/privacy?hl=en'>
                  https://policies.google.com/privacy?hl=en
                </a>
              </p>
              <p dir='ltr'>
                We also encourage you to review the Google's policy for
                safeguarding your data:
                <a href='https://support.google.com/analytics/answer/6004245'>
                  https://support.google.com/analytics/answer/6004245
                </a>
                .
              </p>
              <p dir='ltr'>Firebase</p>
              <p dir='ltr'>
                Firebase is analytics service provided by Google Inc.
              </p>
              <p dir='ltr'>
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy:
                <a href='https://policies.google.com/privacy?hl=en'>
                  https://policies.google.com/privacy?hl=en
                </a>
              </p>
              <p dir='ltr'>
                For more information on what type of information Firebase
                collects, please visit the Google Privacy Terms web page:
                <a href='https://policies.google.com/privacy?hl=en'>
                  https://policies.google.com/privacy?hl=en
                </a>
              </p>
              <p dir='ltr'>Fathom Analytics</p>
              <p dir='ltr'>
                Fathom Analytics is analytics service provided by Conva Ventures
                Inc. You can find their Privacy Policy here:{' '}
                <a href='https://usefathom.com/privacy/'>
                  https://usefathom.com/privacy/
                </a>
              </p>
              <p dir='ltr'>Piwik / Matomo</p>
              <p dir='ltr'>
                Piwik or Matomo is a web analytics service. You can visit their
                Privacy Policy page here:
                <a href='https://matomo.org/privacy-policy'>
                  https://matomo.org/privacy-policy
                </a>
              </p>
              <p dir='ltr'>Clicky</p>
              <p dir='ltr'>
                Clicky is a web analytics service. Read the Privacy Policy for
                Clicky here: https://clicky.com/terms
              </p>
              <p dir='ltr'>Cloudflare analytics</p>
              <p dir='ltr'>
                Cloudflare analytics is a web analytics service operated by
                Cloudflare Inc. Read the Privacy Policy here:
                <a href='https://www.cloudflare.com/privacypolicy/'>
                  https://www.cloudflare.com/privacypolicy/
                </a>
              </p>
              <p dir='ltr'>Statcounter</p>
              <p dir='ltr'>
                Statcounter is a web traffic analysis tool. You can read the
                Privacy Policy for Statcounter here:
                <a href='https://statcounter.com/about/legal/'>
                  https://statcounter.com/about/legal/
                </a>
              </p>
              <p dir='ltr'>Flurry Analytics</p>
              <p dir='ltr'>
                Flurry Analytics service is provided by Yahoo! Inc.
              </p>
              <p dir='ltr'>
                You can opt-out from Flurry Analytics service to prevent Flurry
                Analytics from using and sharing your information by visiting
                the Flurry's Opt-out page:
                <a href='https://dev.flurry.com/secure/optOut.do'>
                  https://dev.flurry.com/secure/optOut.do
                </a>
              </p>
              <p dir='ltr'>
                For more information on the privacy practices and policies of
                Yahoo!, please visit their Privacy Policy page:
                <a href='https://policies.yahoo.com/us/en/yahoo/privacy/policy/index.htm'>
                  https://policies.yahoo.com/us/en/yahoo/privacy/policy/index.htm
                </a>
              </p>
              <p dir='ltr'>Mixpanel</p>
              <p dir='ltr'>Mixpanel is provided by Mixpanel Inc.</p>
              <p dir='ltr'>
                You can prevent Mixpanel from using your information for
                analytics purposes by opting-out. To opt-out of Mixpanel
                service, please visit this page:{' '}
                <a href='https://mixpanel.com/optout/'>
                  https://mixpanel.com/optout/
                </a>
              </p>
              <p dir='ltr'>
                For more information on what type of information Mixpanel
                collects, please visit the Terms of Use page of Mixpanel:{' '}
                <a href='https://mixpanel.com/terms/'>
                  https://mixpanel.com/terms/
                </a>
              </p>
              <p dir='ltr'>Unity Analytics</p>
              <p dir='ltr'>
                Unity Analytics is provided by Unity Technologies.
              </p>
              <p dir='ltr'>
                For more information on what type of information Unity Analytics
                collects, please visit their Privacy Policy page:
                <a href='https://unity3d.com/legal/privacy-policy'>
                  hhttps://unity3d.com/legal/privacy-policy
                </a>
              </p>
              <p dir='ltr'>Azure DevOps</p>
              <p dir='ltr'>
                Azure DevOps is a Software as a service (SaaS) platform from
                Microsoft that provides an end-to-end DevOps toolchain for
                developing and deploying software.
              </p>
              <p dir='ltr'>
                You can find Microsoft Privacy Statement here:
                https://privacy.microsoft.com/en-gb/privacystatement
              </p>
              <ol start='10'>
                <li dir='ltr'>
                  <p dir='ltr'>No Use By Minors</p>
                </li>
              </ol>
              <p dir='ltr'>
                Service is intended only for access and use by individuals at
                least eighteen (18) years old. By accessing or using any of
                Company, you warrant and represent that you are at least
                eighteen (18) years of age and with the full authority, right,
                and capacity to enter into this agreement and abide by all of
                the terms and conditions of Terms. If you are not at least
                eighteen (18) years old, you are prohibited from both the access
                and usage of Service.
              </p>
              <ol start='11'>
                <li dir='ltr'>
                  <p dir='ltr'>Accounts</p>
                </li>
              </ol>
              <p dir='ltr'>
                When you create an account with us, you guarantee that you are
                above the age of 18, and that the information you provide us is
                accurate, complete, and current at all times. Inaccurate,
                incomplete, or obsolete information may result in the immediate
                termination of your account on Service.
              </p>
              <p dir='ltr'>
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must
                notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>
              <p dir='ltr'>
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than you, without appropriate authorization. You
                may not use as a username any name that is offensive, vulgar or
                obscene.
              </p>
              <p dir='ltr'>
                We reserve the right to refuse service, terminate accounts,
                remove or edit content, or cancel orders in our sole discretion.
              </p>
              <ol start='12'>
                <li dir='ltr'>
                  <p dir='ltr'>Intellectual Property</p>
                </li>
              </ol>
              <p dir='ltr'>
                Service and its original content (excluding Content provided by
                users), features and functionality are and will remain the
                exclusive property of Nailzi and its licensors. Service is
                protected by copyright, trademark, and other laws of the United
                States and foreign countries. Our trademarks and trade dress may
                not be used in connection with any product or service without
                the prior written consent of Nailzi.
              </p>
              <ol start='13'>
                <li dir='ltr'>
                  <p dir='ltr'>Error Reporting and Feedback</p>
                </li>
              </ol>
              <p dir='ltr'>
                You may provide us either directly at Nailzi.services@gmail.com
                or via third party sites and tools with information and feedback
                concerning errors, suggestions for improvements, ideas,
                problems, complaints, and other matters related to our Service
                (“Feedback”). You acknowledge and agree that: (i) you shall not
                retain, acquire or assert any intellectual property right or
                other right, title or interest in or to the Feedback; (ii)
                Company may have development ideas similar to the Feedback;
                (iii) Feedback does not contain confidential information or
                proprietary information from you or any third party; and (iv)
                Company is not under any obligation of confidentiality with
                respect to the Feedback. In the event the transfer of the
                ownership to the Feedback is not possible due to applicable
                mandatory laws, you grant Company and its affiliates an
                exclusive, transferable, irrevocable, free-of-charge,
                sub-licensable, unlimited and perpetual right to use (including
                copy, modify, create derivative works, publish, distribute and
                commercialize) Feedback in any manner and for any purpose.
              </p>
              <p dir='ltr'>
                The third party sites and tools mentioned above include the
                following:
              </p>
              <p dir='ltr'>Bugsnag</p>
              <p dir='ltr'>
                Bugsnag is a platform for monitoring and logging stability of
                applications provided by Bugsnag Inc. Please read their Privacy
                Policy here: https://docs.bugsnag.com/legal/privacy-policy/
              </p>
              <p dir='ltr'>ACRA</p>
              <p dir='ltr'>
                ACRA or Application Crash Reports for Android is monitoring
                platform. Please find more information here:{' '}
                <a href='https://github.com/ACRA/acra'>
                  https://github.com/ACRA/acra
                </a>
              </p>
              <p dir='ltr'>Rollbar</p>
              <p dir='ltr'>
                Rollbar is error tracking service provided by Rollbar Inc. Find
                out more here:
                <a href='https://docs.rollbar.com/docs/privacy-policy'>
                  https://docs.rollbar.com/docs/privacy-policy
                </a>
              </p>
              <p dir='ltr'>Sentry</p>
              <p dir='ltr'>
                Sentry is open-source error tracking solution provided by
                Functional Software Inc. More information is available here:{' '}
                <a href='https://sentry.io/privacy/'>
                  https://sentry.io/privacy/
                </a>
              </p>
              <p dir='ltr'>Raygun</p>
              <p dir='ltr'>
                Raygun is automated error monitoring software provided by Raygun
                Limited. Privacy Policy is accessible at{' '}
                <a href='https://raygun.com/privacy/'>
                  https://raygun.com/privacy/
                </a>
              </p>
              <p dir='ltr'>Firebase Crashlytics</p>
              <p dir='ltr'>
                Firebase Crashlytics is bug reporting service provided by Google
                Inc.
              </p>
              <p dir='ltr'>
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy: https://policies.google.com/privacy?hl=en
              </p>
              <p dir='ltr'>
                For more information on what type of information Firebase
                collects, please visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
              </p>
              <ol start='14'>
                <li dir='ltr'>
                  <p dir='ltr'>Links To Other Web Sites</p>
                </li>
              </ol>
              <p dir='ltr'>
                Our Service may contain links to third party web sites or
                services that are not owned or controlled by Nailzi
              </p>
              <p dir='ltr'>
                Nailzi has no control over, and assumes no responsibility for
                the content, privacy policies, or practices of any third party
                web sites or services. We do not warrant the offerings of any of
                these entities/individuals or their websites.
              </p>
              <p dir='ltr'>
                YOU ACKNOWLEDGE AND AGREE THAT NAILZI SHALL NOT BE RESPONSIBLE
                OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED
                OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR
                RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
                THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
              </p>
              <p dir='ltr'>
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
                POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU
                VISIT.
              </p>
              <ol start='15'>
                <li dir='ltr'>
                  <p dir='ltr'>Disclaimer Of Warranty</p>
                </li>
              </ol>
              <p dir='ltr'>
                THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
                OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
                SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
                THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES,
                THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
                YOUR SOLE RISK.
              </p>
              <p dir='ltr'>
                NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
                WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR
                ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
                SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES
                OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
                OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR
                ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR
                NEEDS OR EXPECTATIONS.
              </p>
              <p dir='ltr'>
                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
                LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                AND FITNESS FOR PARTICULAR PURPOSE.
              </p>
              <p dir='ltr'>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <ol start='16'>
                <li dir='ltr'>
                  <p dir='ltr'>Limitation Of Liability</p>
                </li>
              </ol>
              <p dir='ltr'>
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
                DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
                PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER
                IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND
                EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
                APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
                INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR
                OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH
                THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR
                PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT
                AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS,
                STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
                PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
                PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF
                COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS
                AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
                CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
                EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
                DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO
                YOU.
              </p>
              <ol start='17'>
                <li dir='ltr'>
                  <p dir='ltr'>Termination</p>
                </li>
              </ol>
              <p dir='ltr'>
                We may terminate or suspend your account and bar access to
                Service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of Terms.
              </p>
              <p dir='ltr'>
                If you wish to terminate your account, you may simply
                discontinue using Service.
              </p>
              <p dir='ltr'>
                All provisions of Terms which by their nature should survive
                termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
              </p>
              <ol start='18'>
                <li dir='ltr'>
                  <p dir='ltr'>Governing Law</p>
                </li>
              </ol>
              <p dir='ltr'>
                These Terms shall be governed and construed in accordance with
                the laws of Ontario Canada without regard to its conflict of law
                provisions.
              </p>
              <p dir='ltr'>
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service and supersede and replace any prior
                agreements we might have had between us regarding Service.
              </p>
              <ol start='19'>
                <li dir='ltr'>
                  <p dir='ltr'>Changes To Service</p>
                </li>
              </ol>
              <p dir='ltr'>
                We reserve the right to withdraw or amend our Service, and any
                service or material we provide via Service, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of Service is unavailable at any time or
                for any period. From time to time, we may restrict access to
                some parts of Service, or the entire Service, to users,
                including registered users.
              </p>
              <ol start='20'>
                <li dir='ltr'>
                  <p dir='ltr'>Amendments To Terms</p>
                </li>
              </ol>
              <p dir='ltr'>
                We may amend Terms at any time by posting the amended terms on
                this site. It is your responsibility to review these Terms
                periodically.
              </p>
              <p dir='ltr'>
                Your continued use of the Platform following the posting of
                revised Terms means that you accept and agree to the changes.
                You are expected to check this page frequently so you are aware
                of any changes, as they are binding on you.
              </p>
              <p dir='ltr'>
                By continuing to access or use our Service after any revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, you are no longer authorized
                to use Service.
              </p>
              <ol start='21'>
                <li dir='ltr'>
                  <p dir='ltr'>Waiver And Severability</p>
                </li>
              </ol>
              <p dir='ltr'>
                No waiver by Company of any term or condition set forth in Terms
                shall be deemed a further or continuing waiver of such term or
                condition or a waiver of any other term or condition, and any
                failure of Company to assert a right or provision under Terms
                shall not constitute a waiver of such right or provision.
              </p>
              <p dir='ltr'>
                If any provision of Terms is held by a court or other tribunal
                of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision shall be eliminated
                or limited to the minimum extent such that the remaining
                provisions of Terms will continue in full force and effect.
              </p>
              <ol start='22'>
                <li dir='ltr'>
                  <p dir='ltr'>Acknowledgement</p>
                </li>
              </ol>
              <p dir='ltr'>
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE
                TO BE BOUND BY THEM.
              </p>
              <ol start='23'>
                <li dir='ltr'>
                  <p dir='ltr'>Contact Us</p>
                </li>
              </ol>
              <p dir='ltr'>
                Please send your feedback, comments, requests for technical
                support:
              </p>
              <p dir='ltr'>By email: Nailzi.services@gmail.com.</p>
              <div>
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className='service-container'
          align='top'
          style={{ marginTop: 40 }}
        >
          <Col lg={{ span: 20 }}>
            <Divider>
              <h3>Freelancer's Terms Of Service</h3>
            </Divider>
            <div className='home-card home-card-center freelancer-disc'>
              <p dir='ltr'>
                These Terms of Use (“Terms”) govern the access or use by you, an
                individual, from within any country in the world (excluding the
                United States and its territories and possessions and Mainland
                China) of applications, websites, content, products, and
                services (the “Services”) made available by Nailzi Inc. a
                private limited liability company established in Canada, having
                its office in Milton , ON , Canada.
              </p>
              <p dir='ltr'>
                PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
                SERVICES.
              </p>
              <p dir='ltr'>
                Your access and use of the Services constitutes your agreement
                to be bound by these Terms, which establishes a contractual
                relationship between you and Nailzi. If you do not agree to
                these Terms, you may not access or use the Services. These Terms
                expressly supersede prior agreements or arrangements with you.
                Nailzi may immediately terminate these Terms or any Services
                with respect to you, or generally cease offering or deny access
                to the Services or any portion thereof, at any time for any
                reason.
              </p>
              <p dir='ltr'>
                Supplemental terms may apply to certain Services, such as
                policies for a particular event, activity or promotion, and such
                supplemental terms will be disclosed to you in connection with
                the applicable Services. Supplemental terms are in addition to,
                and shall be deemed a part of, the Terms for the purposes of the
                applicable Services. Supplemental terms shall prevail over these
                Terms in the event of a conflict with respect to the applicable
                Services.
              </p>
              <p dir='ltr'>
                Nailzi may amend the Terms related to the Services from time to
                time. Amendments will be effective upon Nailzi’s posting of such
                updated Terms at this location or the amended policies or
                supplemental terms on the applicable Service. Your continued
                access or use of the Services after such posting constitutes
                your consent to be bound by the Terms, as amended.
              </p>
              <p dir='ltr'>
                Our collection and use of personal information in connection
                with the Services is as provided in Nailzi’s Privacy Policy
                located at
                <a href='https://www.uber.com/privacy/notice'>
                  https://www.Nailzi.com/privacy/notice
                </a>
                . Nailzi may provide to a claims processor or an insurer any
                necessary information (including your contact information) if
                there is a complaint, dispute or conflict, which may include an
                accident, involving you and a Third Party Provider (including a
                Beauty services network company driver) and such information or
                data is necessary to resolve the complaint, dispute or conflict.
              </p>
              <p dir='ltr'>2. The Services</p>
              <p dir='ltr'>
                The Services constitute a technology platform that enables users
                of Nailzi’s mobile applications or websites provided as part of
                the Services (each, an “Application”) to arrange and schedule
                beauty services and/or health services with independent third
                party providers of such services, including independent third
                party beauty services providers and independent third party
                grooming providers under agreement with Nailzi or certain of
                Nailzi’s affiliates (“Third Party Providers”). Unless otherwise
                agreed by Nailzi in a separate written agreement with you, the
                Services are made available solely for your personal,
                noncommercial use. YOU ACKNOWLEDGE THAT NAILZI DOES NOT PROVIDE
                BEAUTY OR GROOMING SERVICES OR FUNCTION AS A BEAUTY SALON AND
                THAT ALL SUCH BEAUTY OR GROOMING SERVICES ARE PROVIDED BY
                INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY
                NAILZI OR ANY OF ITS AFFILIATES.
              </p>
              <p dir='ltr'>License.</p>
              <p dir='ltr'>
                Subject to your compliance with these Terms, Nailzi grants you a
                limited, non-exclusive, non-sublicensable, revocable,
                non-transferrable license to: (i) access and use the
                Applications on your personal device solely in connection with
                your use of the Services; and (ii) access and use any content,
                information and related materials that may be made available
                through the Services, in each case solely for your personal,
                noncommercial use. Any rights not expressly granted herein are
                reserved by Nailzi and Nailzi’s licensors.
              </p>
              <p dir='ltr'>Restrictions.</p>
              <p dir='ltr'>
                You may not: (i) remove any copyright, trademark or other
                proprietary notices from any portion of the Services; (ii)
                reproduce, modify, prepare derivative works based upon,
                distribute, license, lease, sell, resell, transfer, publicly
                display, publicly perform, transmit, stream, broadcast or
                otherwise exploit the Services except as expressly permitted by
                Nailzi; (iii) decompile, reverse engineer or disassemble the
                Services except as may be permitted by applicable law; (iv) link
                to, mirror or frame any portion of the Services; (v) cause or
                launch any programs or scripts for the purpose of scraping,
                indexing, surveying, or otherwise data mining any portion of the
                Services or unduly burdening or hindering the operation and/or
                functionality of any aspect of the Services; or (vi) attempt to
                gain unauthorized access to or impair any aspect of the Services
                or its related systems or networks.
              </p>
              <p dir='ltr'>Provision of the Services.</p>
              <p dir='ltr'>
                You acknowledge that portions of the Services may be made
                available under Nailzi’s various brands or request options
                associated with Beauty services or grooming, including the
                Beauty services request brands currently referred to as
                “Nailzi,” “NailziPOP,” “NailziX,” “NailziXL,” “NailziBLACK,”
                “NailziSUV,” “NailziBERLINE,” “NailziVAN,” “NailziEXEC,” and
                “NailziLUX” and the GROOMING request brands currently referred
                to as “NailziRUSH,” “NailziFRESH” and “NailziEATS”. You also
                acknowledge that the Services may be made available under such
                brands or request options by or in connection with: (i) certain
                of Nailzi’s subsidiaries and affiliates; or (ii) independent
                Third Party Providers, including Beauty services network company
                drivers, Beauty services charter permit holders or holders of
                similar Beauty services permits, authorizations or licenses.
              </p>
              <p dir='ltr'>Third Party Services and Content.</p>
              <p dir='ltr'>
                The Services may be made available or accessed in connection
                with third party services and content (including advertising)
                that Nailzi does not control. You acknowledge that different
                terms of use and privacy policies may apply to your use of such
                third party services and content. Nailzi does not endorse such
                third party services and content and in no event shall Nailzi be
                responsible or liable for any products or services of such third
                party providers. Additionally, Apple Inc., Google, Inc.,
                Microsoft Corporation or BlackBerry Limited and/or their
                applicable international subsidiaries and affiliates will be
                third-party beneficiaries to this contract if you access the
                Services using Applications developed for Apple iOS, Android,
                Microsoft Windows, or Blackberry-powered mobile devices,
                respectively. These third party beneficiaries are not parties to
                this contract and are not responsible for the provision or
                support of the Services in any manner. Your access to the
                Services using these devices is subject to terms set forth in
                the applicable third party beneficiary’s terms of service.
              </p>
              <p dir='ltr'>Ownership.</p>
              <p dir='ltr'>
                The Services and all rights therein are and shall remain
                Nailzi’s property or the property of Nailzi’s licensors. Neither
                these Terms nor your use of the Services convey or grant to you
                any rights: (i) in or related to the Services except for the
                limited license granted above; or (ii) to use or reference in
                any manner Nailzi’s company names, logos, product and service
                names, trademarks or services marks or those of Nailzi’s
                licensors.
              </p>
              <p dir='ltr'>3. Your Use of the Services</p>
              <p dir='ltr'>User Accounts.</p>
              <p dir='ltr'>
                In order to use most aspects of the Services, you must register
                for and maintain an active personal user Services account
                (“Account”). You must be at least 18 years of age, or the age of
                legal majority in your jurisdiction (if different than 18), to
                obtain an Account. Account registration requires you to submit
                to Nailzi certain personal information, such as your name,
                address, mobile phone number and age, as well as at least one
                valid payment method (either a credit card or accepted payment
                partner). You agree to maintain accurate, complete, and
                up-to-date information in your Account. Your failure to maintain
                accurate, complete, and up-to-date Account information,
                including having an invalid or expired payment method on file,
                may result in your inability to access and use the Services or
                Nailzi’s termination of these Terms with you. You are
                responsible for all activity that occurs under your Account, and
                you agree to maintain the security and secrecy of your Account
                username and password at all times. Unless otherwise permitted
                by Nailzi in writing, you may only possess one Account.
              </p>
              <p dir='ltr'>User Requirements and Conduct.</p>
              <p dir='ltr'>
                The Service is not available for use by persons under the age of
                18. You may not authorize third parties to use your Account, and
                you may not allow persons under the age of 18 to receive Beauty
                services or grooming services from Third Party Providers unless
                they are accompanied by you. You may not assign or otherwise
                transfer your Account to any other person or entity. You agree
                to comply with all applicable laws when using the Services, and
                you may only use the Services for lawful purposes (e.g., no
                transport of unlawful or hazardous materials). You will not, in
                your use of the Services, cause nuisance, annoyance,
                inconvenience, or property damage, whether to the Third Party
                Provider or any other party. In certain instances you may be
                asked to provide proof of identity to access or use the
                Services, and you agree that you may be denied access to or use
                of the Services if you refuse to provide proof of identity.
              </p>
              <p dir='ltr'>Text Messaging.</p>
              <p dir='ltr'>
                By creating an Account, you agree that the Services may send you
                text (SMS) messages as part of the normal business operation of
                your use of the Services. You may opt-out of receiving text
                (SMS) messages from Nailzi at any time by following the
                directions found at
                <a href='https://www.uber.com/unsubscribe/'>
                  https://www.Nailzi.com/unsubscribe/
                </a>
                . You acknowledge that opting out of receiving text (SMS)
                messages may impact your use of the Services.
              </p>
              <p dir='ltr'>Promotional Codes.</p>
              <p dir='ltr'>
                Nailzi may, in Nailzi’s sole discretion, create promotional
                codes that may be redeemed for Account credit, or other features
                or benefits related to the Services and/or a Third Party
                Provider’s services, subject to any additional terms that Nailzi
                establishes on a per promotional code basis (“Promo Codes”). You
                agree that Promo Codes: (i) must be used for the intended
                audience and purpose, and in a lawful manner; (ii) may not be
                duplicated, sold or transferred in any manner, or made available
                to the general public (whether posted to a public form or
                otherwise), unless expressly permitted by Nailzi; (iii) may be
                disabled by Nailzi at any time for any reason without liability
                to Nailzi; (iv) may only be used pursuant to the specific terms
                that Nailzi establishes for such Promo Code; (v) are not valid
                for cash; and (vi) may expire prior to your use. Nailzi reserves
                the right to withhold or deduct credits or other features or
                benefits obtained through the use of Promo Codes by you or any
                other user in the event that Nailzi determines or believes that
                the use or redemption of the Promo Code was in error,
                fraudulent, illegal, or in violation of the applicable Promo
                Code terms or these Terms.
              </p>
              <p dir='ltr'>User Provided Content.</p>
              <p dir='ltr'>
                Nailzi may, in Nailzi’s sole discretion, permit you from time to
                time to submit, upload, publish or otherwise make available to
                Nailzi through the Services textual, audio, and/or visual
                content and information, including commentary and feedback
                related to the Services, initiation of support requests, and
                submission of entries for competitions and promotions (“User
                Content”). Any User Content provided by you remains your
                property. However, by providing User Content to Nailzi, you
                grant Nailzi a worldwide, perpetual, irrevocable, transferrable,
                royalty-free license, with the right to sublicense, to use,
                copy, modify, create derivative works of, distribute, publicly
                display, publicly perform, and otherwise exploit in any manner
                such User Content in all formats and distribution channels now
                known or hereafter devised (including in connection with the
                Services and Nailzi’s business and on third-party sites and
                services), without further notice to or consent from you, and
                without the requirement of payment to you or any other person or
                entity.
              </p>
              <p dir='ltr'>
                You represent and warrant that: (i) you either are the sole and
                exclusive owner of all User Content or you have all rights,
                licenses, consents and releases necessary to grant Nailzi the
                license to the User Content as set forth above; and (ii) neither
                the User Content nor your submission, uploading, publishing or
                otherwise making available of such User Content nor Nailzi’s use
                of the User Content as permitted herein will infringe,
                misappropriate or violate a third party’s intellectual property
                or proprietary rights, or rights of publicity or privacy, or
                result in the violation of any applicable law or regulation.
              </p>
              <p dir='ltr'>
                You agree to not provide User Content that is defamatory,
                libelous, hateful, violent, obscene, pornographic, unlawful, or
                otherwise offensive, as determined by Nailzi in its sole
                discretion, whether or not such material may be protected by
                law. Nailzi may, but shall not be obligated to, review, monitor,
                or remove User Content, at Nailzi’s sole discretion and at any
                time and for any reason, without notice to you.
              </p>
              <p dir='ltr'>Network Access and Devices.</p>
              <p dir='ltr'>
                You are responsible for obtaining the data network access
                necessary to use the Services. Your mobile network’s data and
                messaging rates and fees may apply if you access or use the
                Services from a wireless-enabled device and you shall be
                responsible for such rates and fees. You are responsible for
                acquiring and updating compatible hardware or devices necessary
                to access and use the Services and Applications and any updates
                thereto. Nailzi does not guarantee that the Services, or any
                portion thereof, will function on any particular hardware or
                devices. In addition, the Services may be subject to
                malfunctions and delays inherent in the use of the Internet and
                electronic communications.
              </p>
              <p dir='ltr'>4. Payment</p>
              <p dir='ltr'>
                You understand that use of the Services may result in charges to
                you for the services or goods you receive from a Third Party
                Provider (“Charges ”). After you have received services or goods
                obtained through your use of the Service, Nailzi will facilitate
                your payment of the applicable Charges on behalf of the Third
                Party Provider as such Third Party Provider’s limited payment
                collection agent. Payment of the Charges in such manner shall be
                considered the same as payment made directly by you to the Third
                Party Provider. Charges will be inclusive of applicable taxes
                where required by law. Charges paid by you are final and
                non-refundable, unless otherwise determined by Nailzi. You
                retain the right to request lower Charges from a Third Party
                Provider for services or goods received by you from such Third
                Party Provider at the time you receive such services or goods.
                Nailzi will respond accordingly to any request from a Third
                Party Provider to modify the Charges for a particular service or
                good.
              </p>
              <p dir='ltr'>
                All Charges are due immediately and payment will be facilitated
                by Nailzi using the preferred payment method designated in your
                Account, after which Nailzi will send you a receipt by email. If
                your primary Account payment method is determined to be expired,
                invalid or otherwise not able to be charged, you agree that
                Nailzi may, as the Third Party Provider’s limited payment
                collection agent, use a secondary payment method in your
                Account, if available.
              </p>
              <p dir='ltr'>
                As between you and Nailzi, Nailzi reserves the right to
                establish, remove and/or revise Charges for any or all services
                or goods obtained through the use of the Services at any time in
                Nailzi’s sole discretion. Further, you acknowledge and agree
                that Charges applicable in certain geographical areas may
                increase substantially during times of high demand. Nailzi will
                use reasonable efforts to inform you of Charges that may apply,
                provided that you will be responsible for Charges incurred under
                your Account regardless of your awareness of such Charges or the
                amounts thereof. Nailzi may from time to time provide certain
                users with promotional offers and discounts that may result in
                different amounts charged for the same or similar services or
                goods obtained through the use of the Services, and you agree
                that such promotional offers and discounts, unless also made
                available to you, shall have no bearing on your use of the
                Services or the Charges applied to you. You may elect to cancel
                your request for services or goods from a Third Party Provider
                at any time prior to such Third Party Provider’s arrival, in
                which case you may be charged a cancellation fee.
              </p>
              <p dir='ltr'>
                This payment structure is intended to fully compensate the Third
                Party Provider for the services or goods provided. Except with
                respect to taxicab Beauty services services requested through
                the Application, Nailzi does not designate any portion of your
                payment as a tip or gratuity to the Third Party Provider. Any
                representation by Nailzi (on Nailzi’s website, in the
                Application, or in Nailzi’s marketing materials) to the effect
                that tipping is “voluntary,” “not required,” and/or “included”
                in the payments you make for services or goods provided is not
                intended to suggest that Nailzi provides any additional amounts,
                beyond those described above, to the Third Party Provider. You
                understand and agree that, while you are free to provide
                additional payment as a gratuity to any Third Party Provider who
                provides you with services or goods obtained through the
                Service, you are under no obligation to do so. Gratuities are
                voluntary. After you have received services or goods obtained
                through the Service, you will have the opportunity to rate your
                experience and leave additional feedback about your Third Party
                Provider.
              </p>
              <p dir='ltr'>Repair or Cleaning Fees.</p>
              <p dir='ltr'>
                You shall be responsible for the cost of repair for damage to,
                or necessary cleaning of, Third Party Provider vehicles and
                property resulting from use of the Services under your Account
                in excess of normal “wear and tear” damages and necessary
                cleaning (“Repair or Cleaning”). In the event that a Third Party
                Provider reports the need for Repair or Cleaning, and such
                Repair or Cleaning request is verified by Nailzi in Nailzi’s
                reasonable discretion, Nailzi reserves the right to facilitate
                payment for the reasonable cost of such Repair or Cleaning on
                behalf of the Third Party Provider using your payment method
                designated in your Account. Such amounts will be transferred by
                Nailzi to the applicable Third Party Provider and are
                non-refundable.
              </p>
              <p dir='ltr'>
                5. Disclaimers; Limitation of Liability; Indemnity.
              </p>
              <p dir='ltr'>DISCLAIMER.</p>
              <p dir='ltr'>
                THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” NAILZI
                DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED
                OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, NAILZI
                MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE
                RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF
                THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE
                OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR
                ERROR-FREE. NAILZI DOES NOT GUARANTEE THE QUALITY, SUITABILITY,
                SAFETY OR ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE
                ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY
                SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS
                SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER
                APPLICABLE LAW.
              </p>
              <p dir='ltr'>LIMITATION OF LIABILITY.</p>
              <p dir='ltr'>
                NAILZI SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
                EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
                PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED
                TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF
                THE SERVICES, EVEN IF NAILZI HAS BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES. NAILZI SHALL NOT BE LIABLE FOR ANY DAMAGES,
                LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE
                ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES;
                OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY
                THIRD PARTY PROVIDER, EVEN IF NAILZI HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. NAILZI SHALL NOT BE LIABLE FOR
                DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND
                NAILZI’S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY
                BEAUTY SERVICES PROVIDERS PROVIDING BEAUTY SERVICES SERVICES
                REQUESTED THROUGH SOME REQUEST BRANDS MAY OFFER RIDESHARING OR
                PEER-TO-PEER BEAUTY SERVICES SERVICES AND MAY NOT BE
                PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL NAILZI’S
                TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL
                DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIVE HUNDRED EUROS
                (€500).
              </p>
              <p dir='ltr'>
                NAILZI’S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE
                BEAUTY SERVICES, GOODS OR GROOMING SERVICES WITH THIRD PARTY
                PROVIDERS, BUT YOU AGREE THAT NAILZI HAS NO RESPONSIBILITY OR
                LIABILITY TO YOU RELATED TO ANY BEAUTY SERVICES, GOODS OR
                GROOMING SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER
                THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
              </p>
              <p dir='ltr'>
                THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT
                TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT
                CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
              </p>
              <p dir='ltr'>Indemnity.</p>
              <p dir='ltr'>
                You agree to indemnify and hold Nailzi and its officers,
                directors, employees and agents harmless from any and all
                claims, demands, losses, liabilities, and expenses (including
                attorneys’ fees) arising out of or in connection with: (i) your
                use of the Services or services or goods obtained through your
                use of the Services; (ii) your breach or violation of any of
                these Terms; (iii) Nailzi’s use of your User Content; or (iv)
                your violation of the rights of any third party, including Third
                Party Providers.
              </p>
              <p dir='ltr'>6. Governing Law; Arbitration.</p>
              <p dir='ltr'>
                Except as otherwise set forth in these Terms, these Terms shall
                be exclusively governed by and construed in accordance with the
                laws of The Netherlands, excluding its rules on conflicts of
                laws. The Vienna Convention on the International Sale of Goods
                of 1980 (CISG) shall not apply. Any dispute, conflict, claim or
                controversy arising out of or broadly in connection with or
                relating to the Services or these Terms, including those
                relating to its validity, its construction or its enforceability
                (any “Dispute”) shall be first mandatorily submitted to
                mediation proceedings under the International Chamber of
                Commerce Mediation Rules (“ICC Mediation Rules”). If such
                Dispute has not been settled within sixty (60) days after a
                request for mediation has been submitted under such ICC
                Mediation Rules, such Dispute can be referred to and shall be
                exclusively and finally resolved by arbitration under the Rules
                of Arbitration of the International Chamber of Commerce (“ICC
                Arbitration Rules”). The ICC Rules' Emergency Arbitrator
                provisions are excluded. The Dispute shall be resolved by one
                (1) arbitrator to be appointed in accordance with the ICC Rules.
                The place of both mediation and arbitration shall be Canada, The
                Netherlands, without prejudice to any rights you may have under
                Article 18 of the Brussels I bis Regulation (OJ EU 2012 L351/1)
                and/or Article 6:236n of the Dutch Civil Code. The language of
                the mediation and/or arbitration shall be English, unless you do
                not speak English, in which case the mediation and/or
                arbitration shall be conducted in both English and your native
                language. The existence and content of the mediation and
                arbitration proceedings, including documents and briefs
                submitted by the parties, correspondence from and to the
                International Chamber of Commerce, correspondence from the
                mediator, and correspondence, orders and awards issued by the
                sole arbitrator, shall remain strictly confidential and shall
                not be disclosed to any third party without the express written
                consent from the other party unless: (i) the disclosure to the
                third party is reasonably required in the context of conducting
                the mediation or arbitration proceedings; and (ii) the third
                party agrees unconditionally in writing to be bound by the
                confidentiality obligation stipulated herein.
              </p>
              <p dir='ltr'>7. Other Provisions</p>
              <p dir='ltr'>Claims of Copyright Infringement.</p>
              <p dir='ltr'>
                Claims of copyright infringement should be sent to Nailzi’s
                designated agent. Please visit Nailzi’s web page at{' '}
                <a href='https://www.uber.com/legal'>
                  https://www.Nailzi.com/legal
                </a>{' '}
                for the designated address and additional information.
              </p>
              <p dir='ltr'>Notice.</p>
              <p dir='ltr'>
                Nailzi may give notice by means of a general notice on the
                Services, electronic mail to your email address in your Account,
                or by written communication sent to your address as set forth in
                your Account. You may give notice to Nailzi by written
                communication to Nailzi's address at 145-1000 Asleton Blvd
                Milton ON Canada.
              </p>
              <p dir='ltr'>General.</p>
              <p dir='ltr'>
                You may not assign or transfer these Terms in whole or in part
                without Nailzi’s prior written approval. You give your approval
                to Nailzi for it to assign or transfer these Terms in whole or
                in part, including to: (i) a subsidiary or affiliate; (ii) an
                acquirer of Nailzi’s equity, business or assets; or (iii) a
                successor by merger. No joint venture, partnership, employment
                or agency relationship exists between you, Nailzi or any Third
                Party Provider as a result of the contract between you and
                Nailzi or use of the Services.
              </p>
              <p dir='ltr'>
                If any provision of these Terms is held to be illegal, invalid
                or unenforceable, in whole or in part, under any law, such
                provision or part thereof shall to that extent be deemed not to
                form part of these Terms but the legality, validity and
                enforceability of the other provisions in these Terms shall not
                be affected. In that event, the parties shall replace the
                illegal, invalid or unenforceable provision or part thereof with
                a provision or part thereof that is legal, valid and enforceable
                and that has, to the greatest extent possible, a similar effect
                as the illegal, invalid or unenforceable provision or part
                thereof, given the contents and purpose of these Terms. These
                Terms constitute the entire agreement and understanding of the
                parties with respect to its subject matter and replaces and
                supersedes all prior or contemporaneous agreements or
                undertakings regarding such subject matter. In these Terms, the
                words “including” and “include” mean “including, but not limited
                to.”
              </p>
              <br />
            </div>
          </Col>
        </Row>
      </Content>
      <div className='download-container'>
        <Row justify='center' align='middle'>
          <Col>
            <div className='download-card'>
              <h3>Download NAILZI for iOS</h3>
              <img
                style={{ marginBottom: '30px' }}
                src={require('../assets/appleDownloadLogo.png')}
              />
              <h3>Download NAILZI for Android</h3>
              <img src={require('../assets/androidDownloadLogo.png')} />
            </div>
          </Col>
        </Row>
      </div>
      <Footer className='footer'>
        <Row justify='space-between'>
          <Col span={6}>
            <ul className='footer-menu'>
              <li>
                <Link to='/terms'>Terms of Service</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default Terms;
