import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { FirebaseAppProvider, AuthCheck } from 'reactfire';
import firebaseConfig from './utils/firebaseConfig';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import Loader from './pages/Loader';
import Terms from './pages/Terms';

const App = () => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <Route exact path='/'>
              <div className='rootApp'>
                <Home />
              </div>
            </Route>

            <Route exact path='/terms'>
              <div className='rootApp'>
                <Terms />
              </div>
            </Route>

            <Route path='/login'>
              <LoginPage />
            </Route>
            <PrivateRoute path='/admin'>
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </FirebaseAppProvider>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <AuthCheck
      fallback={
        <Redirect
          to={{
            pathname: '/login',
            state: { from: '/authenticated' },
          }}
        />
      }
    >
      <Route {...rest} render={({ location }) => children} />
    </AuthCheck>
  );
};

export default App;
