import React from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const FreelancerRate = (props) => {
  const firestore = useFirestore();
  const getOrders = useFirestore()
    .collection('orders')
    .where('acceptedBy', '==', props.fid);
  const { status: orderStatus, data: orders } =
    useFirestoreCollectionData(getOrders);

  const calculateMeanValue = (theorder) => {
    let total = 0;
    for (let i = 0; i < theorder.length; i++) {
      total += theorder[i].freelancerRating;
    }
    return total / theorder.length;
  };

  return (
    <div>{orderStatus === 'success' ? calculateMeanValue(orders) : 0}</div>
  );
};

export default FreelancerRate;
