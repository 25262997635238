import React, { Suspense, useState, useRef } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Loader from './Loader';
import {
  Table,
  Switch,
  Card,
  Row,
  Col,
  Avatar,
  Image,
  Divider,
  Modal,
  Input,
  Space,
  Button,
} from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import FreelancerRate from '../components/FreeLancerRate';

const FreelancerSettings = (props) => {
  const firestore = useFirestore();
  const getFreelancers = useFirestore()
    .collection('users')
    .where('freelancer', '==', true);
  const { status: freelancersStatus, data: freelancers } =
    useFirestoreCollectionData(getFreelancers);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const getOrders = useFirestore().collection('orders');
  const { status: ordersStatus, data: orders } =
    useFirestoreCollectionData(getOrders);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const calculateFreelancersBalance = (id) => {
    const today = moment();
    let weekStart = today.clone().startOf('isoWeek');
    let weekEnd = today.clone().endOf('isoWeek');

    let getFilteredFreelancers = orders.reduce((result, oditem) => {
      if (
        oditem.acceptedBy === id &&
        moment(oditem.createdAt).isBetween(weekStart, weekEnd)
      ) {
        result.push(oditem);
      }
      return result;
    }, []);

    let total = getFilteredFreelancers.reduce(
      (accumulator, current) => accumulator + current.freelancerBalance,
      0
    );
    return total;
  };

  const calculateTotalFreelancerBalance = (id) => {
    let getFilteredFreelancers = orders.reduce((result, oditem) => {
      if (oditem.acceptedBy === id) {
        result.push(oditem);
      }
      return result;
    }, []);

    let sorted = getFilteredFreelancers.sort(function (a, b) {
      return moment(a.createdAt).unix() - moment(b.createdAt).unix();
    });

    let total = sorted.reduce(
      (accumulator, current) => accumulator + current.freelancerBalance,
      0
    );
    return total;
  };

  return (
    <Suspense fallback={<Loader />}>
      <Table
        loading={freelancersStatus === 'loading'}
        columns={[
          {
            title: 'Id',
            dataIndex: 'userId',
            key: 'userId',
            ...getColumnSearchProps('userId'),
          },
          {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            responsive: ['lg'],
            ellipsis: true,
            ...getColumnSearchProps('firstName'),
          },
          {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            responsive: ['md'],
            ...getColumnSearchProps('lastName'),
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            ...getColumnSearchProps('email'),
          },
          {
            title: 'Weekly Balance',
            dataIndex: 'balance',
            key: 'balance',
            responsive: ['md'],

            render: (text, record) => {
              return calculateFreelancersBalance(record.NO_ID_FIELD) > 0
                ? calculateFreelancersBalance(record.NO_ID_FIELD) + ' CAD'
                : 0 + ' CAD';
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => {
              return (
                <Switch
                  defaultChecked={record.active}
                  onChange={() => {
                    console.log('log-record.NO_ID_FIELD', record.NO_ID_FIELD);
                    firestore
                      .collection('users')
                      .doc(record.NO_ID_FIELD)
                      .set(
                        {
                          active: !record.active,
                          coords: { latitude: 0, longitude: 0 },
                        },
                        { merge: true }
                      )
                      .then((r) => {
                        Modal.success({
                          title: 'Awesome!',
                          content: `User successfully ${
                            record.active ? 'deactivated' : 'activated'
                          }.`,
                        });
                      });
                  }}
                />
              );
            },
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <Card>
              <Divider>Freelancer Information</Divider>
              <Row gutter={10} justify='center' align='top'>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 6 }}
                  style={{ textAlign: 'center' }}
                >
                  {record.profilePic && (
                    <Avatar
                      size='large'
                      shape='rounded'
                      style={{
                        width: 150,
                        height: 150,
                        marginBottom: 10,
                        boxShadow: '0px 0px 12px 0px rgba(117, 117, 117, 1)',
                        alignItems: 'center',
                        display: 'inline-flex',
                      }}
                      src={<Image src={record.profilePic} />}
                    />
                  )}
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h4>Name:</h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.firstName} {'  '} {record.lastName}
                  </h4>
                  <h4>Email:</h4>
                  <h4 style={{ color: 'gray' }}>{record.email}</h4>
                  <h4>Past Experiences:</h4>
                  <h4 style={{ color: 'gray' }}>{record.pastExperiences}</h4>
                  <h4>Average Rate:</h4>
                  <FreelancerRate fid={record.NO_ID_FIELD} />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h4>Phone:</h4>
                  <h4 style={{ color: 'gray' }}>{record.phone}</h4>
                  <h4>Date of Birth:</h4>
                  <h4 style={{ color: 'gray' }}>
                    {' '}
                    {moment(record.dob, 'MM-DD-YYYY HH:mm:ss a').format(
                      'DD-MM-YYYY'
                    )}
                  </h4>
                  <h4>Total Balance</h4>
                  <h4 style={{ color: 'gray' }}>
                    {calculateTotalFreelancerBalance(record.NO_ID_FIELD) > 0
                      ? calculateTotalFreelancerBalance(record.NO_ID_FIELD) +
                        ' CAD'
                      : 0 + ' CAD'}
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h4>Coords:</h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.coords && record.coords.latitude} <br />
                    {record.coords && record.coords.longitude}
                  </h4>
                  <h4>Created at</h4>
                  <h4 style={{ color: 'gray' }}>
                    {moment(record.createdAt).format('DD-MM-YYYY')}
                  </h4>
                  <h4>Services</h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.nail && record.nail && 'Nails'}
                  </h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.eyebrows && record.eyebrows && 'Eyebrows'}
                  </h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.eyelash && record.eyelash && 'Eyelash'}
                  </h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.waxing && record.waxing && 'Waxing'}
                  </h4>
                </Col>
              </Row>
              <Row gutter={10} justify='center' align='middle'>
                <Col
                  xs={{ span: 12 }}
                  lg={{ span: 8 }}
                  style={{ textAlign: 'center' }}
                >
                  <Divider>Government ID</Divider>
                  {record.issuedId && (
                    <Avatar
                      size='large'
                      shape='square'
                      style={{
                        width: '100%',
                        height: '100%',
                        marginBottom: 20,
                        borderRadius: 15,
                        boxShadow: '0px 0px 12px 0px rgba(117, 117, 117, 1)',
                      }}
                      src={<Image src={record.issuedId} />}
                    />
                  )}
                </Col>
                <Col
                  xs={{ span: 12 }}
                  lg={{ span: 8 }}
                  style={{ textAlign: 'center' }}
                >
                  <Divider>SIN Card</Divider>

                  {record.sinCard && (
                    <Avatar
                      size='large'
                      shape='square'
                      style={{
                        width: '100%',
                        height: '100%',
                        marginBottom: 20,
                        borderRadius: 15,
                        boxShadow: '0px 0px 12px 0px rgba(117, 117, 117, 1)',
                      }}
                      src={<Image src={record.sinCard} />}
                    />
                  )}
                </Col>
                <Col
                  xs={{ span: 12 }}
                  lg={{ span: 8 }}
                  style={{ textAlign: 'center' }}
                >
                  <Divider>Equipments</Divider>
                  {record.picOfEquipment && (
                    <Avatar
                      size='large'
                      shape='square'
                      style={{
                        width: '100%',
                        height: '100%',
                        marginBottom: 20,
                        borderRadius: 15,
                        boxShadow: '0px 0px 12px 0px rgba(117, 117, 117, 1)',
                      }}
                      src={<Image src={record.picOfEquipment} />}
                    />
                  )}
                </Col>
              </Row>
              <Divider>Bank Information</Divider>
              <Row gutter={10} justify='center' align='top'>
                <Col xs={{ span: 12 }} lg={{ span: 10 }}>
                  <h4>Account Name: {record.bankAccountName}</h4>
                  <h4>Account Number: {record.bankAccountNumber}</h4>
                  <h4>Transit Number: {record.bankTransitNumber}</h4>
                </Col>
                <Col
                  xs={{ span: 12 }}
                  lg={{ span: 7 }}
                  style={{ textAlign: 'center' }}
                >
                  {record.bankInfoDoc && (
                    <Avatar
                      size='large'
                      shape='square'
                      style={{
                        width: '100%',
                        height: '100%',
                        marginBottom: 20,
                        borderRadius: 15,
                        boxShadow: '0px 0px 12px 0px rgba(117, 117, 117, 1)',
                      }}
                      src={<Image src={record.bankInfoDoc} />}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          ),
        }}
        dataSource={freelancers.map((flitem, flindex) => ({
          ...flitem,
          rowid: flindex,
        }))}
        rowKey='NO_ID_FIELD'
      />
    </Suspense>
  );
};

export default FreelancerSettings;
