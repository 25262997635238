import React from 'react';
import { useFirestore, useFirestoreDocData, useFirestoreDoc } from 'reactfire';
import {
  Col,
  Row,
  List,
  Button,
  InputNumber,
  Form,
  Modal,
  Divider,
  Card,
  Input,
} from 'antd';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const SettingsPage = () => {
  const firestore = useFirestore();
  const getservices = useFirestore().collection('services');
  const { status, data: services } = useFirestoreDoc(getservices);

  const settingsData = useFirestore().collection('settings');
  const { status: settingsLoading, data: settings } =
    useFirestoreDoc(settingsData);

  const promosData = useFirestore().collection('promos');
  const { status: promosLoading, data: promos } = useFirestoreDoc(promosData);

  const renderService = () => {
    let renderedService = [];
    services.forEach((change) => {
      renderedService.push(
        <Col key={change.id} xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form
            className='service-card'
            onFinish={(values) => {
              let newData = change.data();
              let newServiceValue = [];

              for (let i in values) {
                let thePrice = values[i]
                  ? values[i]
                  : newData.subServices[i].price;
                newServiceValue.push({
                  ...newData.subServices[i],
                  price: thePrice,
                });
              }

              firestore
                .collection('services')
                .doc(change.id)
                .set({
                  ...change.data(),
                  subServices: newServiceValue,
                })
                .then((r) => {
                  Modal.success({
                    title: 'Awesome!',
                    content: 'Service successfully updated.',
                  });
                });
            }}
          >
            <List
              loading={status === 'loading'}
              header={
                <div className='service-card-header'>
                  <h3>{change.data().name}</h3>
                </div>
              }
              footer={
                <div className='service-card-footer'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{ width: '-webkit-fill-available' }}
                  >
                    Save
                  </Button>
                </div>
              }
              bordered
              dataSource={change.data().subServices}
              renderItem={(item, index) => (
                <List.Item
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                    fontSize: 'small',
                  }}
                >
                  <div style={{ flex: 1, textAlign: 'center' }}>
                    {item.name}
                  </div>
                  <div style={{ flex: 0, textAlign: 'center' }}>
                    <Form.Item
                      style={{
                        marginBottom: 0,
                        marginTop: 0,
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                      name={index}
                    >
                      <InputNumber style={{ paddingBottom: 0 }} min={0} />
                    </Form.Item>
                  </div>
                  <div style={{ flex: 1, textAlign: 'center' }}>
                    <h5>Current Price</h5>
                    <h5>{item.price} CAD</h5>
                  </div>
                </List.Item>
              )}
            />
          </Form>
        </Col>
      );
    });
    return renderedService;
  };

  return (
    <div>
      <Divider>Price Settings</Divider>
      <Row gutter={[8, 16]}>{renderService()}</Row>
      <Row gutter={[8, 16]} justify='center' style={{ marginTop: 50 }}>
        <Divider>Other Settings</Divider>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card className='service-card'>
            <h3>Radius</h3>
            <Form
              {...layout}
              initialValues={{
                radius: settings ? settings.docs[0].data().radius : 0,
              }}
              onFinish={(values) => {
                firestore
                  .collection('settings')
                  .doc(settings.docs[0].id)
                  .set(values, { merge: true })
                  .then((r) => {
                    Modal.success({
                      title: 'Awesome!',
                      content: 'Settings successfully updated.',
                    });
                  });
              }}
            >
              <Form.Item label='Radius' name='radius'>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
              <div className='service-card-footer'>
                <Button type='primary' htmlType='submit'>
                  Save Settings
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsPage;
