import React, { Suspense, useState } from 'react';
import { Col, Layout, Menu, Row, Drawer } from 'antd';
import { Link, Route, Switch } from 'react-router-dom';
import SettingsPage from './SettingsPage';
import CustomerSettings from './CustomerSettings';
import FreelancerSettings from './FreelancerSettings';
import Transactions from './Transactions';
import ActiveOrders from './ActiveOrders';
import { useAuth } from 'reactfire';
import PromoCodes from './PromoCodes';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import Banners from './Banners';

const { Header, Content, Footer, Sider } = Layout;

const signOut = (auth) => auth.signOut().then(() => console.log('signed out'));

const Dashboard = (props) => {
  const auth = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);

  const routes = [
    {
      path: '/admin',
      exact: true,
      sidebar: () => (
        <Menu.Item key='admin'>
          <Link to='/admin'>Service Settings</Link>
        </Menu.Item>
      ),
      main: () => <SettingsPage />,
    },
    {
      path: '/admin/customers',
      sidebar: () => (
        <Menu.Item key='customers'>
          <Link to='/admin/customers'>Customers Settings</Link>
        </Menu.Item>
      ),
      main: () => <CustomerSettings />,
    },
    {
      path: '/admin/freelancers',
      sidebar: () => (
        <Menu.Item key='freelancers'>
          <Link to='/admin/freelancers'>Freelancer Settings</Link>
        </Menu.Item>
      ),
      main: () => <FreelancerSettings />,
    },
    {
      path: '/admin/orders',
      sidebar: () => (
        <Menu.Item key='orders'>
          <Link to='/admin/orders'>Orders</Link>
        </Menu.Item>
      ),
      main: () => <ActiveOrders />,
    },
    {
      path: '/admin/promocodes',
      sidebar: () => (
        <Menu.Item key='promocodes'>
          <Link to='/admin/promocodes'>Promo Codes</Link>
        </Menu.Item>
      ),
      main: () => <PromoCodes />,
    },
    {
      path: '/admin/transactions',
      sidebar: () => (
        <Menu.Item key='transactions'>
          <Link to='/admin/transactions'>Transactions</Link>
        </Menu.Item>
      ),
      main: () => <Transactions />,
    },
    {
      path: '/admin/banners',
      sidebar: () => (
        <Menu.Item key='banners'>
          <Link to='/admin/banners'>Banners</Link>
        </Menu.Item>
      ),
      main: () => <Banners />,
    },
  ];

  return (
    <Layout>
      <Header className='header'>
        <Row justify='space-between'>
          {!collapsed && (
            <Col span={1}>
              <MenuUnfoldOutlined
                className='header-toggle-btn'
                onClick={() => {
                  setVisible(true);
                }}
              />
            </Col>
          )}
          <Col span={2}>
            <Link to='/'>Nailzi.com</Link>
          </Col>
          <Col span={1}>
            <div className='header-logo'>
              <Link to='/login'>
                <img src={require('../assets/logoWhite.png')} />
              </Link>
            </div>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider
          trigger={null}
          breakpoint='lg'
          collapsible
          collapsedWidth='0'
          style={{ background: '#fff' }}
          onBreakpoint={(broken) => {
            console.log('broken:', broken);
            setCollapsed(broken);
          }}
          onCollapse={(collapsed, type) => {
            setCollapsed(!collapsed);
          }}
        >
          <div className='logo' />
          <Menu theme='light' mode='inline'>
            {routes.map((route, index) => route.sidebar())}

            <Menu.Item
              key='logout'
              onClick={() => {
                signOut(auth);
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Sider>

        <Drawer
          title={
            <div className='sidebar-logo'>
              <h3>Nailzi.com</h3>
            </div>
          }
          className='sidebar'
          placement='left'
          closable={false}
          onClose={() => {
            setVisible(false);
          }}
          visible={visible}
        >
          <div className='logo' />
          <Menu
            theme='light'
            mode='inline'
            onClick={() => {
              setVisible(false);
            }}
          >
            {routes.map((route, index) => route.sidebar())}

            <Menu.Item
              key='logout'
              onClick={() => {
                signOut(auth);
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Drawer>

        <Content style={{ margin: '24px 16px 0' }}>
          <div
            className='site-layout-background'
            style={{ padding: 24, minHeight: 360 }}
          >
            <Switch>
              {routes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </div>
        </Content>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>
        Nailzi ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default Dashboard;
