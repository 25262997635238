import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, InputNumber, Select, Switch } from 'antd';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { ReloadOutlined } from '@ant-design/icons';

const { Option } = Select;

const CreateCoupon = (props) => {
  const [form] = Form.useForm();
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);

  useEffect(() => form.resetFields(), [props.data]);

  const onFinish = (values) => {
    setLoading(true);
    if (props.data) {
      firestore
        .collection('promos')
        .doc(props.data.NO_ID_FIELD)
        .set(values, { merge: true })
        .then((r) => {
          Modal.success({
            title: 'Awesome!',
            content: 'Coupon successfully updated.',
          });
          setLoading(false);
          props.onSuccess();
          form.resetFields();
        });
    } else {
      firestore
        .collection('promos')
        .add(values)
        .then((r) => {
          if (r.id) {
            Modal.success({
              title: 'Awesome!',
              content: 'Coupon successfully created.',
            });
            props.onSuccess();
            form.resetFields();
          } else {
            Modal.error({
              title: 'Sorry!',
              content: 'Cannot create coupon.',
            });
          }
          setLoading(false);
        });
    }
  };

  const randomString = (length = 20) => {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        code: props.data ? props.data.code : '',
        detail: props.data ? props.data.detail : '',
        value: props.data ? props.data.value : '',
        forAll: props.data ? props.data.forAll : true,
        customers: props.data ? props.data.customers : [],
        voucher: props.data ? props.data.voucher : false,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label='Code'
        name='code'
        rules={[
          {
            required: true,
            message: 'Please input code!',
          },
        ]}
      >
        <Input
          placeholder='Code'
          addonAfter={
            <Button
              size='small'
              type='primary'
              icon={
                <ReloadOutlined
                  onClick={() => {
                    let genCode = randomString(6);
                    form.setFieldsValue({
                      code: genCode,
                    });
                  }}
                />
              }
            />
          }
        />
      </Form.Item>

      <Form.Item
        label={
          <>
            <span className='mr-10'>Value</span>
            <Form.Item noStyle name='voucher' valuePropName='checked'>
              <Switch checkedChildren='Amount' unCheckedChildren='Percentage' />
            </Form.Item>
          </>
        }
        name='value'
        rules={[
          {
            required: true,
            message: 'Please input value!',
          },
        ]}
      >
        <InputNumber
          min={0}
          max={100}
          style={{ width: '100%', marginTop: 10 }}
          placeholder='Value'
        />
      </Form.Item>

      <Form.Item
        label='For all Customers'
        name='forAll'
        valuePropName='checked'
      >
        <Switch defaultChecked checkedChildren='Yes' unCheckedChildren='No' />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.forAll !== currentValues.forAll
        }
      >
        {({ getFieldValue }) =>
          !getFieldValue('forAll') ? (
            <Form.Item label='Select Customers' name='customers'>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {props.users &&
                  props.users.map((uitem, uindex) => {
                    return (
                      <Option key={uitem.NO_ID_FIELD} value={uitem.NO_ID_FIELD}>
                        {uitem.email}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item label='Detail' name='detail'>
        <Input placeholder='Detail' />
      </Form.Item>

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          block
          loading={loading}
          disabled={loading}
        >
          {props.data ? 'Update' : 'Create'} Coupon
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          disabled={loading}
          type='danger'
          block
          onClick={() => {
            props.onCancel();
            form.resetFields();
          }}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateCoupon;
