import React from 'react';
import { Layout, Row, Col, Form, Button, Input, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';
import Loader from './Loader';
const { Header, Footer, Content } = Layout;

const LoginPage = (props) => {
  const auth = useAuth();
  const { data: user } = useUser();
  console.log('log-user', user);

  const onFinish = (values) => {
    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then((resp) => {
        if (resp.user && resp.user.uid) {
          window.location = '/admin';
        }
      })
      .catch((err) => {
        Modal.error({
          title: 'Sorry!',
          content: err.message,
        });
      });
  };

  return (
    <Layout className='rootApp'>
      <Header className='header'>
        <Row justify='space-between'>
          <Col span={2}>
            <Link to='/'>Nailzi.com</Link>
          </Col>
          <Col span={1}>
            <div className='header-logo'>
              <Link to='/login'>
                <img src={require('../assets/logoWhite.png')} />
              </Link>
            </div>
          </Col>
        </Row>
      </Header>
      <Content className='home-content'>
        <Row justify='center'>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <div className='home-card login-card'>
              <div className='home-card-centered'>
                <div className='login-logo'>
                  <img src={require('../assets/logo.png')} />
                </div>
                <h1>Admin Login</h1>
              </div>
              <Form
                name='normal_login'
                className='login-form'
                initialValues={{ email: '', password: '' }}
                onFinish={onFinish}
              >
                <Form.Item
                  name='email'
                  rules={[
                    { required: true, message: 'Please input your email!' },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    { required: true, message: 'Please input your Password!' },
                  ]}
                >
                  <Input type='password' placeholder='Password' />
                </Form.Item>

                <Form.Item>
                  <Button type='primary' htmlType='submit' block>
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>
        <Row justify='space-between'>
          <Col span={6}>
            <ul className='footer-menu'>
              <li>
                <Link to='/'>Terms of Service</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default LoginPage;
