import React, { Suspense, useState } from 'react';
import Loader from './Loader';
import {
  Button,
  PageHeader,
  Row,
  Table,
  Col,
  Modal,
  Card,
  Divider,
  Avatar,
  Image,
} from 'antd';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CreateCoupon from '../components/CreateCoupon';
import moment from 'moment';

const PromoCodes = (props) => {
  const firestore = useFirestore();
  const getPromos = useFirestore().collection('promos');
  const { status: promosStatus, data: promos } =
    useFirestoreCollectionData(getPromos);
  const getUsers = useFirestore()
    .collection('users')
    .where('customer', '==', true);
  const { status: usersStatus, data: users } =
    useFirestoreCollectionData(getUsers);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [single, setSingle] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <Suspense fallback={<Loader />}>
      <PageHeader>
        <Row justify='space-between'>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 4 }}>
            <Button
              type='primary'
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Add Coupon
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Table
        loading={promosStatus === 'loading' || loading}
        columns={[
          {
            title: 'Id',
            dataIndex: 'rowid',
            key: 'rowid',
          },
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            responsive: ['lg'],
          },
          {
            title: 'For All',
            dataIndex: 'forAll',
            key: 'forAll',
            responsive: ['lg'],
            render: (text) => {
              return text ? 'Yes' : 'No';
            },
          },
          {
            title: 'Details',
            dataIndex: 'detail',
            key: 'detail',
            responsive: ['md'],
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) =>
              text === null ? 0 : text + (record.voucher ? ' CAD' : ' %'),
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
              return (
                <Button.Group size='small'>
                  <Button
                    type='primary'
                    icon={<EditOutlined />}
                    onClick={() => {
                      setSingle(record);
                      setIsModalVisible(true);
                    }}
                  />
                  <Button
                    type='danger'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setLoading(true);
                      firestore
                        .collection('promos')
                        .doc(record.NO_ID_FIELD)
                        .delete()
                        .then((r) => {
                          Modal.success({
                            title: 'Success!',
                            content: 'Coupon has been deleted.',
                          });
                          setLoading(false);
                        });
                    }}
                  />
                </Button.Group>
              );
            },
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <Card>
              <Divider>Coupon Information</Divider>
              <Row gutter={10} justify='center' align='top'>
                <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                  <Divider>Details</Divider>
                  <div className='text-center'>
                    <h4>{record.detail}</h4>
                  </div>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                  <Divider>Code Information</Divider>
                  <div className='text-center'>
                    <h4>Code: {record.code}</h4>
                    <h4>For All: {record.forAll ? 'Yes' : 'No'}</h4>
                  </div>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                  <Divider>Value</Divider>
                  <div className='text-center'>
                    <h4>Value: {record.value} CAD</h4>
                    <h4>Voucher: {record.voucher ? 'Yes' : 'No'}</h4>
                  </div>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                  <Divider>Customer</Divider>
                  <div className='text-center'>
                    {!record.forAll &&
                      record.customers &&
                      record.customers.map((csitem, csindex) => {
                        let customer = users.filter(
                          (csuser) => csuser.NO_ID_FIELD === csitem
                        );
                        return (
                          <div key={csindex.toString()}>
                            <h4>
                              Name:{' '}
                              {customer && customer[0]
                                ? customer[0].firstName +
                                  ' ' +
                                  customer[0].lastName
                                : ''}
                            </h4>
                            <h4>
                              Email:{' '}
                              {customer && customer[0] ? customer[0].email : ''}
                            </h4>
                            <Divider />
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </Card>
          ),
        }}
        dataSource={promos.map((promoitem, promoindex) => ({
          ...promoitem,
          rowid: promoindex,
        }))}
        rowKey='NO_ID_FIELD'
      />
      <Modal
        title={`${single ? 'Update' : 'Create'} Coupon`}
        visible={isModalVisible}
        closable={false}
        maskClosable={false}
        footer={null}
      >
        <CreateCoupon
          users={users}
          data={single}
          onSuccess={() => {
            setSingle(false);
            setIsModalVisible(false);
          }}
          onCancel={() => {
            setSingle(false);
            setIsModalVisible(false);
          }}
        />
      </Modal>
    </Suspense>
  );
};

export default PromoCodes;
