import React, { Suspense, useState, useRef } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import Loader from './Loader';
import {
  Table,
  Switch,
  Card,
  Row,
  Col,
  Avatar,
  Image,
  Divider,
  Modal,
  Input,
  Space,
  Button,
} from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

const CustomerSettings = (props) => {
  const getCustomers = useFirestore()
    .collection('users')
    .where('customer', '==', true);
  const { status: customersStatus, data: customers } =
    useFirestoreCollectionData(getCustomers);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  return (
    <Suspense fallback={<Loader />}>
      <Table
        loading={customersStatus === 'loading'}
        columns={[
          {
            title: 'Id',
            dataIndex: 'userId',
            key: 'userId',
            ...getColumnSearchProps('userId'),
          },
          {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            responsive: ['lg'],
            ...getColumnSearchProps('firstName'),
          },
          {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            responsive: ['md'],
            ...getColumnSearchProps('lastName'),
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            ...getColumnSearchProps('email'),
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <Card>
              <Divider>Customer Information</Divider>
              <Row gutter={10} justify='center' align='top'>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 6 }}
                  style={{ textAlign: 'center' }}
                >
                  {record.profilePic && (
                    <Avatar
                      size='large'
                      shape='rounded'
                      style={{
                        width: 150,
                        height: 150,
                        marginBottom: 10,
                        boxShadow: '0px 0px 12px 0px rgba(117, 117, 117, 1)',
                        alignItems: 'center',
                        display: 'inline-flex',
                      }}
                      src={<Image src={record.profilePic} />}
                    />
                  )}
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h4>Name:</h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.firstName} {'  '} {record.lastName}
                  </h4>
                  <h4>Email:</h4>
                  <h4 style={{ color: 'gray' }}>{record.email}</h4>
                  <h4>Phone:</h4>
                  <h4 style={{ color: 'gray' }}>{record.phone}</h4>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h4>Date of Birth:</h4>
                  <h4 style={{ color: 'gray' }}>
                    {' '}
                    {moment(record.dob, 'MM-DD-YYYY HH:mm:ss a').format(
                      'DD-MM-YYYY'
                    )}
                  </h4>

                  <h4>Address:</h4>
                  <h5 style={{ color: 'gray' }}>
                    Street: {record.street}
                    <br />
                    Unit/Flat: {record.unit}
                    <br />
                    Provice: {record.state}
                    <br />
                    Postal Code: {record.zip}
                    <br />
                    City: {record.city}
                    <br />
                    Country: {record.country}
                    <br />
                  </h5>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h4>Coords:</h4>
                  <h4 style={{ color: 'gray' }}>
                    {record.coords && record.coords.latitude} <br />
                    {record.coords && record.coords.longitude}
                  </h4>
                  <h4>Created at</h4>
                  <h4 style={{ color: 'gray' }}>
                    {moment(record.createdAt).format('DD-MM-YYYY')}
                  </h4>
                </Col>
              </Row>
            </Card>
          ),
        }}
        dataSource={customers.map((customeritem, customerindex) => ({
          ...customeritem,
          rowid: customerindex,
        }))}
        rowKey='NO_ID_FIELD'
      />
    </Suspense>
  );
};

export default CustomerSettings;
