import React, { useState } from 'react';
import { Layout, Row, Col, Divider, Carousel } from 'antd';
import { Link } from 'react-router-dom';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import ReadMoreReact from 'read-more-react';

const { Header, Footer, Content } = Layout;

const Home = (props) => {
  const getBanners = useFirestore().collection('banners');
  const yourTextHere =
    'Services is all about making your vision the reality! Our services provides you the luxury of staying at home and having our professionals come right to your doorstep to pamper you. From nail  services to waxing and eyelashes, we provide top notch professionals with extensive experience to make sure you are satisfied! Kick back in your pyjamas and let our professionals do the magic by simply selecting the date and time that suits you throug  <b> Nailzi </b>  Application. View and give reviews on professionals, and dont worry about missing an appointment we will make sure to remind you.';
  const minimumLength = 430;
  const idealLength = 450;
  const maxLength = 500;
  const readMore = 'Read more ..';

  const { status: bannersStatus, data: banners } =
    useFirestoreCollectionData(getBanners);

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className='text'>
        {isReadMore ? text.slice(0, 600) : text}
        <a onClick={toggleReadMore} className='read-more-button'>
          {isReadMore ? '  ...read more' : ' show less'}
        </a>
      </p>
    );
  };

  return (
    <Layout>
      <Header className='header'>
        <Row justify='space-between'>
          <Col span={2}>
            <Link to='/'>Nailzi.com</Link>
          </Col>
          <Col span={1}>
            <div className='header-logo'>
              <Link to='/login'>
                <img src={require('../assets/logoWhite.png')} />
              </Link>
            </div>
          </Col>
        </Row>
      </Header>
      <Content className='home-content'>
        <Row className='title-content'>
          <h1>Welcome to </h1>
          <img src={require('../assets/nailzi.png')} />
        </Row>
        <Row className='service-container'>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Divider>Our Services</Divider>

            <div className='home-card home-card-center service-card service-card-bg-wihte'>
              <img src={require('../assets/eyebrows.png')} />
              <img src={require('../assets/eyelash.png')} />
              <img src={require('../assets/naglar.png')} />
              <img src={require('../assets/waxing.png')} />
            </div>
          </Col>

          <Row className='banner-container' justify='center'>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <div className='home-card banner-section'>
                <Carousel autoplay>
                  {banners &&
                    banners.map((bitem, bindex) => {
                      return (
                        <div key={bitem.NO_ID_FIELD}>
                          <img
                            style={{
                              width: '100%',
                              objectFit: 'fill',
                            }}
                            src={bitem.image}
                          />
                        </div>
                      );
                    })}
                </Carousel>
              </div>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <p
                className='about-section'
                style={{ fontSize: 13, textAlign: 'justify' }}
              >
                <p style={{ fontSize: 18 }}>
                  <b>Nailzi Beauty Service App </b>
                </p>
                <ReadMore>
                  Need an amazing eyelashs and eyebrow waxing, dashing look and
                  fascinating nail art, our mobile beauty shop has you covered.
                  As the world is suffering from the deadliest effects of
                  pandemic COVID-19, everyone is restricted to home and towns.
                  Your hair might get split ends, nails worn out and the skin
                  turns tarninsh due to stress of staying at home. Home working
                  has really makes us sluggish and frazzled. Although beauty
                  salons are officially open but you might avoid it due to
                  social distancing or some places provide services to limited
                  quantity than the usual. Moving to salon mostly involves
                  public transport and more contact to people that you might not
                  like. Obviously, you might not disturb your health by doing
                  this so. NAILZI MOBILE BEAUTY SHOPS Sometimes the only thing
                  that keep you away from staying fresh is that you don’t want
                  to go outside. This may disturb your self care that you used
                  to get from beauty salons. Don’t worry, welcome to thw world
                  of beauty at home where finding and booking appointments from
                  eyelash extention to killer party look without leaving your
                  comfort of home, is super easy. When there is no time left to
                  move outside for salon or spa and wait for your turn just sign
                  up on our app and make an appointment on your suitable time.
                  Our beauty app is already there to serve you to retain your
                  beauty and skin care routine with doorstep service. Nailzi
                  provides safe side treatments to you with the place where you
                  feel comfortable and relaxed and get yourself treated. Our
                  services are halted during the lockdown and many clients are
                  getting booked up quickly. Search and look for the top rated
                  professionals for any occasion from your job interview to
                  parties. Just wish a treatment with our app and our
                  professionals and beauticians will meet you on your desired
                  place. We don’t care how your house looks like or whether you
                  are doing laundry, just open the door and sit back and let the
                  professionals do their magi for you. All the service and
                  quality you used to get in a salon or spa, now its in the
                  comfort of your home. BENEFITS OF OUR APP According to a
                  survey, it has been anticipated that 91% consumers are getting
                  their professional treatments at home due to pandemic. So,
                  everyone is in need to get the help of professionals by
                  staying at home service which include nails, waxing,
                  eyelashes, manicure and pedicure and all the beauty services.
                  Nailzi Beauty service is all about making the beauty at peace
                  for you. Our app aims at making you feel comfortable at home
                  with all the equipments and services. The major benefit of
                  using our mobile beauty shop is that you can have all your
                  treatments in one appointment like our dear customer can book
                  a reservation for more than treatment for example having a
                  meni and pedi with body wax, it can be done in one
                  appointment. Rather to take a long detour to busy salons,
                  nails speacialist and beautician, we are providing you hygiene
                  certified beauty professional with PPE kit at your doorstep.
                  Even our beauty app is also offering to provide service at
                  anywhere like balconies, by windows so the environment is more
                  relaxed and refreshing than a salon.Having our beauty app give
                  you another advantage of flexibillity of timing, you can
                  access all the services and make an appointment before 10PM.
                  Best of all our beauty app is easy to use and operate.
                  LOCATIONS Our user friendly mobile shop is providing services
                  in Canada( Torronto GTA, Ottawa, Montreal Vancouver and
                  Calgary). Our app is just like a dream come true. You can
                  book, reschedule, get appointment reminders and fix time of
                  your choice through our app. Our app is super efficient and
                  competent to schedule a message for you all before 10PM in the
                  specified locations of Canada. You can have our
                  recommendations if you are not sure what your skin needs and
                  suits with our ispirational professionals with whom you can
                  kick back your wearisome and dismal look. HOW TO USE THE APP?
                  Customers can use the app effortlessly making an appointment
                  without calling to a specific salon.They are elligible to sign
                  up on the app free of cost via play store or apple store. Push
                  notifications keep the client updated and informed about
                  beauty products and their appointments. Clients can use chat
                  messages to contact a beautician personally and get beauty
                  tips and inspiration. They are allowed to make payment method
                  of their chosen mood. Users are advised to rate and give
                  reviews on the app. Our salons has all the solutions for
                  bringing up freshness and perfection in your beauty. Our
                  beauty app helps you to capitalize on the potential that your
                  are holding inside. Beauticians access the destined locations
                  of the customers and seeking them on time. Our app has set up
                  everything well to make a platform feasible for beauticians
                  and customers. Our app is extremly user friendly, highly
                  scaleable and feature rich. CONCLUSION Need top-notch nail,
                  hair and waxing services then but why limit yourself. Indulge
                  in a nail art, body wax, makeup, eyelash extention with
                  personal trainer in the comfort of your own home. Turn your
                  home into a beauty salon service whether you need a treatment
                  in 30 minutes or more than that, our top rated stylist and
                  makeup artist are always ready to ring your doorbell and gets
                  you gorgious while you sit back in your bed. Our app lets you
                  sift through the wide range of specialists from wedding events
                  to interviews or treating yourself. Available in Canada(
                  Torronto GTA, Ottawa, Montreal Vancouver and Calgary). Our
                  solutions are not expensive to burn holes in your pocket. We
                  never compromise on the quality with the complete team of
                  techs and professionals who serves you with the best beauty
                  service in your area. Kick back in your bed and let our
                  professionals do the magic by simpling chose the best time
                  that suits you. There is nothing which you can not do by using
                  our app!
                </ReadMore>
              </p>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <p
                className='about-section'
                style={{ fontSize: 13, textAlign: 'justify' }}
              >
                <p style={{ fontSize: 18 }}>
                  <b>Nailzi Beauty App Trend of beauty products </b>
                </p>
                <ReadMore>
                  A fresh start is more than enough for starting over; it’s all
                  about to liberating yourself from the past and bringing a
                  clean pan that lies in you. That’s why instead of dwelling on
                  what had happened in the last few years of COVID-19, we need
                  to choose what is good to come for us in the coming years. In
                  the past years, we have seen some severe changes and situation
                  in our life due to pandemic which has caused a lot of changes
                  in beauty industry. The beauty industry is ever sprouting as
                  beauty standards are lifting up with the change in our lives.
                  It means looking forward to multiple skincare trends that had
                  shaken up the industry and our routine life. The current
                  situation of statistics of beauty products are: • The beauty
                  industry is growing at the rate of 5.2% each year from2020 to
                  2023. • The top beauty product markets are China, India,
                  Japan, Brazil, UK and USA. • United Kingdom and United States
                  of America are the largest buyers of beauty products around
                  the globe. Some trends of beauty product were thrown back by
                  COVID-19 but few become popular during the lockdown while
                  staying at home to avoid contact.  Skincare has become very
                  popular in few years. It all focuses on glowing skin without
                  wearing makeup to prevent wrinkles and aging. Few products
                  like moisturizers, cleansers, serums, and exfoliants are in
                  high demand. These products have become axioms in beauty
                  industry.  People are becoming perplexed of what they should
                  use for their body and which product suits them. This is due
                  to the fact that many beauty products contain contagious
                  chemicals and ingredients which are undesirable by everyone.
                  These products are getting less demanding for both persons and
                  environment. As everything not consumed or absorbed by us
                  turns back to environment and water. So many people are taking
                  care of receiving naturally prepared beauty products
                  regardless of expensive prices  Hand sanitizer is not
                  considered to be part of beauty products but since the
                  situations got complex, companies have started preparing their
                  own hand sanitizers. Alcohol is the main ingredient of it
                  which makes hand dry and dull that’s why it is made effort to
                  create moisturizing hand sanitizer. Companies are making such
                  formulas for hand sanitizer that will make hands soft and
                  nourished with germ free protections.  Wearing masks for
                  longer period of time while staying at work or outside the
                  home, makes your skin dry, dull and cause breakout. Companies
                  are producing masks that are designed to prevent all these
                  issues. These masks will be likely to be used as long as
                  COVID-19 stays as an issue. Impact of COVID-19 on daily life
                  The beauty industry has been known for its ever evolving
                  nature. The COVID-19 pandemic has made the huge impact on
                  every industry that exists. The beauty industry is employed
                  despite the pandemic and engaged in making a mask less regular
                  life.  What is happening when salons and beauty boutiques are
                  shut down for the sake of social distancing and reducing the
                  impacts of contagion during the lockdown? Many people found
                  themselves tasked with their beauty routines and skin care.
                  From grooming and cutting hair to taking care of skin instead
                  of being able to rely on some extra help from outside. COVID
                  has created an ecommerce bench for all the existing industry.
                  Online shopping has been more popular than ever before and
                  beauty products are trending on the top.  Facial skincare
                  products are occupying major part in beauty products. Our face
                  is the main part that needs to be glowed up in every event and
                  occasion whether an interview, wedding ceremony and party. Add
                  mask to your face and you may realize how delicate your skin
                  is and how much care it requires. Some beauty products are in
                  trend which prevents your skin from drying out and breaking
                  down. Many people do not use skincare products but they are
                  forced to do so due to maskne. They continued to use it
                  because of unexpected and happy results from them. Beauty
                  companies are looking forward to create amazing products that
                  feel good, look good and perform well. These companies are
                  producing cosmetics raw materials which contain such products
                  and ingredients.  Not just beauty but all the verticals from
                  food to fitness are shifted towards digital mindset and are
                  forced to embrace ecommerce, however for the beauty products
                  the question arises that how the customers would be able to
                  find the best thing without testing it like shades of lipstick
                  and fragrance but many companies and brands have created
                  virtual makeup line and rooms via filters on Instagram and
                  snap chat with thriving quizzes that let you customize with
                  haircut and skincare online.  Color cosmetics are the largest
                  beauty category while it has an unsurprising drop in lockdown.
                  Skincare products usage has been accelerate by 18.9% since
                  2020. Blue light treatment is designed to keep pore protected
                  in the intense face screen time.  Virtual reality (VR) have
                  become the popular places to visit on our smartphones that
                  have expanded our world in lockdown by offers and promoting
                  new launches of beauty products.  The Global Wellness
                  Institute has recorded worth of 4.5$ trillion economy focusing
                  on Beauty and Personal Care, Fitness, Nutrition, Preventative
                  and Personalized Medicine, Spas and Personal Pampering and
                  Wellness Tourism and Real Estate. COVID has accelerated the
                  rate with 1/3 of consumers in the past year.  Beauty products
                  and services are changing while consumers are staying at home.
                  90% women are not wearing makeup or low makeup while at home
                  and they do not go to salons for skin care setup.  Regardless
                  of pandemic, every brand is trying to reach out their
                  customers with online services and digital platform. They are
                  trying to rebound and staying stronger in these situations.
                  While salons and spa are closed in the pandemic, consumers are
                  forced to stay at home and find their beauty alternatives
                  themselves. Mobile Beauty Shops  Virtual events are taking
                  place of live events and beauty industries are reaching out
                  their clients to keep everyone connected and updated during
                  the times of quarantine, lockdown and social distancing.
                  Webinars are held everywhere that keeps the customers linked
                  with their skin care products. A lot of jobs are digital and
                  remote working is the best choice for everyone in current
                  situations.  New challenges end up with new victories.
                  Industrial events are coming online that help cooperate and
                  business leaders from all across the personal care and
                  cosmetics industry.  Independent beauty products are facing
                  extreme challenges. They are working in groups as hard as to
                  keep everyone stay linked and cognizant with beauty industry
                  and products as best as possible.  For retailers and salons,
                  COVID-19 has appeared to be a real challenge due lockdowns all
                  over the world. But they started working again by the use of
                  digital media and online services which provide you with every
                  facility at home.  Customers can buy every beauty product
                  through mobile beauty shop. They can order everything and can
                  decide whether they want to keep or return the product over a
                  course of time- a very excellent conservational way to keep
                  business moving even in such situations.  A desire to save
                  money on travel is also making people to keep themselves stay
                  at home while accessing everything online through mobile
                  beauty shop.  Brands are trying to sell online products
                  through tutorials, virtual assistant and home services to use
                  their products.  Even during the worst situation due to
                  COVID-19, consumers are investing on beauty services at home
                  Is it possible to get beauty services at home? Yes, it is
                  possible to get beauty and skin care treatment at home. As the
                  world is suffering and social distancing is better for your
                  health to avoid contact with people. But the question arises
                  here that how we can get the same treatment at home just like
                  in salons and spa. The answer is so simple that everything is
                  getting digitalized and modern technology has overcome the
                  world and has reduced your efforts for travelling a long
                  distance to move somewhere just for few hours treatment, you
                  can get the same effect at home. Nailzi Beauty App Nailzi
                  Beauty service and app has got you covered with the best
                  treatments from professionals for you effortlessly. Whether
                  you want the best and amazing nail art, body waxing, and
                  eyelash extension, you can find the best options with the
                  guidance of professionals from our app service. Nailzi is a
                  beauty and wellness app which is providing its services in
                  Canada (Toronto GTA, Ottawa, Montreal Vancouver and Calgary).
                  Our Services Our customers can use our app free of cost and
                  effortlessly. What you need to do is just a simple sign up
                  with easy steps that keep your security at next level by
                  downloading from play store or apple store. The biggest
                  advantage of using our app service is that you can access all
                  the treatments with the best professionals and beauticians at
                  your specified time and place. You can have nail art, body
                  waxing and eyebrows treatment and eyelashes extension in one
                  appointment. Nail Art Our nail art category is to all the best
                  ways for making your special day more glorious by decorating
                  and enhancing your nails that add more touch to your beauty.
                  There are varieties of designs available for you ranging from
                  elusive to funky and sassy. You could have a flower painted on
                  your nails and or any design of your choice. We start by
                  taking care of your nails by cleaning and shaping them. If you
                  nails have been down to ends, don’t need to worry at all. We
                  will apply artificial acrylic nails on your existing skin to
                  give it a damn look and design. We have got each color of nail
                  paint that will set your mood and outfit to exceptional and
                  marvelous looks in you. Eyelash Extension Our eyelash
                  extension services will add natural fibers to your eyes that
                  will make your lash look fringe, lengthier, bushier and
                  murkier. We are offering extension length, curls and tints so
                  you can customize your beauty with it. Our eyelash extension
                  service is completely customized, pain free and virtually
                  waterproof. Eyebrow Waxing Our eyebrow waxing service will be
                  totally pain free; it is long lasting than tweezing or
                  threading. Our eyebrow waxing service will broaden your face
                  by highlighting your eyes. It will be time saver in your busy
                  schedule with the ease of your home. Our service will be able
                  to remove your dead skin cells and limits ingrown hair. Our
                  beauty app will help you customize and enhance the inner
                  beauty in you. Our beauticians and professionals will reach
                  you out at your destined locations and ready to knock at your
                  door at any time. You can book and make an appointment,
                  reschedule it and fix time and place of your choice. You can
                  call our professionals at any place whether your room, balcony
                  or any refreshing point where you feel cozy and calm. It is
                  more probably like a dream come true. Without costly prices,
                  you can turn your dull mood and face into glorious and
                  glamorous look. You can access the services by 10PM in your
                  location. Our team of techs and professional will be available
                  to you any time. We are providing amazing services at low cost
                  but we have never compromised on the quality. You can select
                  payment method of your choice. We will never let you to miss
                  any appointment by giving you remainders, furthermore if you
                  are getting confused and worried about your beauty, our
                  inspiration will lift your spirit beyond the horizons. Our
                  hygiene certified beauty professionals will appear with PPE
                  kit at your doorstep. Why limit yourself still, grab your
                  phone, install the app, find the best service for yourself,
                  make an appointment with date and time, and ready to open the
                  door. Boom! Stay in your bed and let our professionals do the
                  magic for you. We are only concerned with your beauty and skin
                  care. There is nothing which you can’t have from our app
                  service.
                </ReadMore>
              </p>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <div className='ads-cantainer'>
                <img src={require('../assets/ads.png')} />
              </div>
            </Col>
          </Row>

          <Row
            justify='space-around'
            justify='center'
            align='top'
            style={{ marginTop: 20 }}
          >
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
              <div className='appImage-cantainer'>
                <img src={require('../assets/app.png')} />
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <p
                className='about-section'
                style={{ fontSize: 13, textAlign: 'justify' }}
              >
                <p style={{ fontSize: 18 }}>
                  <b>Our Mobile Beauty Services </b>
                </p>
                <ReadMore>
                  Nailzi is proud to provide our new Mobile Beauty Services.
                  Nailzi’s Mobile splendor offerings are right here to make your
                  existence easy. Our Mobile Beauty Services provide you the
                  posh of staying at reception and having our professionals come
                  right to the doorstep to pamper you. From nail services to
                  waxing and eyelashes, we offer top-notch professionals with
                  extensive experience to make sure you're satisfied! Kick lower
                  back in your pajamas and let our gurus do the magic by
                  absolutely choosing the date and time that fits you via Nailzi
                  Application. In the 21st century we will have literally
                  anything delivered to the doorstep . Hungry? You can order
                  food from countless locations. Too tired to go grocery
                  shopping? You can have somebody else get your groceries. Don’t
                  like shopping for clothes? Not a drag — you'll access
                  thousands of stores online and shop from the comfort of your
                  home. But did you recognize that mobile beauty services are
                  also a thing now? That’s right! You don’t even need to attend
                  a salon to get high-quality treatments done. Thanks to Nailzi
                  mobile beauty service, you can get the most sought-after
                  procedures done right in your living room. If you're curious
                  about this innovative service, keep reading to find out more!
                  What Are Mobile Beauty Services? Mobile beauty services by
                  Nailzi will simplify your life. We understand that each woman
                  likes to look and feel beautiful — that’s only natural.
                  However, not all people love going to beauty salons. Some
                  consider it a waste of time, while others have hectic
                  schedules and they find it hard to plan and book appointments.
                  Also, some women don’t like crowded salons and that they
                  prefer quiet areas with not too many of us around. We’ve
                  decided to create mobile top beauty services to meet the needs
                  of all our clients. Those who don’t enjoy spending hours in
                  busy beauty salons can now get top-notch services wherever
                  they need them. Our mobile top beauty services are often at
                  your address in no time, able to cause you to desire a queen.
                  Just schedule a meeting, tell us where you would like us to
                  return, and our professional and experienced technicians are
                  going to be at your door quicker than you'd imagine. Why
                  Choose a Mobile Beauty Salon? The answer to the present
                  question is fairly simple — because it’s convenient and safe.
                  Those who don’t revel in spending hours in busy splendor
                  salons can now get top-notch offerings anyplace they want. Our
                  mobile salon provides you with ultimate privacy and makes sure
                  you get the simplest possible service without leaving your
                  home. Also, it allows you to save time since there’s no got to
                  go anywhere — everything is going to be done at your property.
                  Regarding the procedures themselves, you can expect them to
                  last just as long as those in our regular beauty salon. Our
                  talented and experienced technicians won’t attempt to finish
                  the work in record time and rush to their next appointment.
                  You can make certain they're going to do the work meticulously
                  and confirm you're satisfied once they're done. Quality
                  remains one of Nailzi mobile top beauty services main goals.
                  It doesn’t matter if you come to our salon or we come to your
                  place — you will constantly get top-notch services. How to
                  Make an Appointment? All you need to do is Download our mobile
                  app, contact us and let us know you are interested in our
                  mobile beauty salon. Our staff will first ask you about the
                  procedures you're curious about . You can book many of the
                  treatments our studio offers, a few of which include: 1.
                  Mobile Nails 2. Mobile Eyelashes 3. Mobile Eyebrows 4. Mobile
                  Waxing All these issues will be discussed in detail in the
                  next episode. All that’s left to do is relax and await our
                  team to return and cause you to look and feel your best. Are
                  Mobile top Beauty Services the proper Choice for You? If you
                  don't like busy beauty salons and spending time in traffic,
                  but you do wish to look your best and feel feminine at the
                  same time, mobile toronto beauty services are ideal for you.
                  Thanks to our mobile Toronto beauty service, you'll enjoy
                  high-quality procedures without leaving your home. What does
                  that mean? Well, a lot of things. For starters, you'll have
                  your regular appointments at reception rather than at our
                  salon . By inviting our pro team to your home, you'll save
                  time and still get the lashes, nails, and skin of your dreams.
                  For example, you'll enjoy a knowledgeable facial at your house
                  — isn’t that amazing?! Also, our mobile salon is ideal for
                  special occasions. Book our team for your birthday,
                  anniversary, prom, bachelorette party, wedding, or other
                  important event, and we’ll ensure you and your closest gals
                  look and desire royalty. Book Mobile toronto Beauty Services
                  Now We’re sure you've already realized the multiple benefits
                  our mobile salon offers. If you’re not one of those women who
                  particularly enjoy going to beauty salons and chatting with
                  other people there, we are positive you are going to love our
                  mobile GTA beauty services. By booking Nailzi mobile beauty
                  service, you’ll get the best possible service, as well as
                  complete privacy — it’s a combination you shouldn’t miss out
                  on. Contact us today and make the most of our mobile top GTA
                  beauty services before we get fully booked! Our service
                  delivery and staffing procedures: Are you a splendor expert
                  with capabilities in waxing, fixing eyebrows or making nails?
                  Then you’ve come to the right place! Please see the steps
                  below to observe as a splendor specialist. Download our app
                  from the apple store or play store and fill out an utility so
                  you can get listed as an expert inside our application After
                  we review your application, we will send you an email to let
                  you recognize if you have met the necessities to be enlisted
                  inside our app. Once you are approved, you will login with
                  your credentials to the application, the place all you want to
                  do is set your availability and then begin receiving requests.
                  Whenever a client makes an order, Nailzi goes through the
                  accessible freelancers, rounds the customer’s location, and
                  sends a request to all the splendor experts in the area. The
                  one who accepts it first will get the appointment. Only card
                  repayments are accepted, extra important points related to
                  fees and repayments will be shared over the email with the
                  freelancer. Freelancers can cancel up to ninety minutes
                  earlier than the appointment. If the freelancer cancels within
                  ninety minutes from the appointment time, they will be charged
                  25% of the whole order value. Advantages of being a beauty
                  expert with Nailzi: * Receive 10 appointments a week! * With
                  over 10,000 modern customers and counting, Nailzi will assist
                  you attain out to a massive base of customers. * No signal up
                  costs Thank you very much for visiting our mobile GTA beauty
                  service.
                </ReadMore>
              </p>
            </Col>
          </Row>
          <Row justify='center' align='top' style={{ marginTop: 40 }}>
            <Col lg={{ span: 20 }}>
              <Divider>Freelancers</Divider>
              <div className='home-card home-card-center freelancer-disc'>
                <p>
                  <b>
                    Are you a beauty specialist with skills in waxing, fixing
                    eyebrows or making nails ?
                  </b>
                  <br />
                  <br />
                  Then you’ve come to the right place! Please see the steps
                  below to apply as a beauty specialist. 
                  <br />
                  Download our app from apple store or play store and fill an
                  application so you can get listed as a professional in our
                  application
                  <br />
                  After we review your application, we will send you an email to
                  let you know if you have met the requirements to be enlisted
                  within our app.
                  <br />
                  Once you are approved, you will login with your credentials to
                  the application, where all you need to do is set your
                  availability and then start receiving requests.
                  <br />
                  Whenever a customer makes an order, Nailzi goes through the
                  available freelancers around the customer’s area and sends a
                  request to all the beauty specialists in the area. Whoever
                  accepts first, will get the appointment.
                  <br />
                  Whenever a customer makes an order, the app goes through the
                  available freelancers around the customer who provide this
                  service and sends a request to all of them. Whoever accepts it
                  first, will get the appointment.
                  <br />
                  Only card payments are accepted, more details regarding rates
                  and payments will be shared over the email with the
                  freelancer.
                  <br />
                  Freelancers can cancel upto 90 mins before the appointment, if
                  the freelancer cancels within 90 mins from appointment time,
                  they will be charged 25% of the total order value.
                  <br />
                  Perks of being a Beauty Specialist with Nailizi:
                  <br />
                  * Receive an average of 10 appointments a week !
                  <br />
                  * With over 10,000 current users and counting, Nailzi will
                  help you reach out to a large base of customers.
                  <br />* No sign up fees
                </p>
              </div>
            </Col>
          </Row>
        </Row>
      </Content>
      <div className='download-container'>
        <Row justify='center' align='middle'>
          <Col>
            <div className='download-card'>
              <h3>Download NAILZI for iOS</h3>
              <a href='https://apps.apple.com/se/app/nailzi/id1570450337?l=en'>
                {' '}
                <img
                  style={{ marginBottom: '30px' }}
                  src={require('../assets/appleDownloadLogo.png')}
                />
              </a>

              <h3>Download NAILZI for Android</h3>
              <a href='https://play.google.com/store/apps/details?id=com.nailzi'>
                <img src={require('../assets/androidDownloadLogo.png')} />
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <Footer className='footer'>
        <Row justify='space-between'>
          <Col span={6}>
            <ul className='footer-menu'>
              <li>
                <Link to='/terms'>Terms of Service</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default Home;
