import React, { useEffect, useState } from 'react';
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDoc,
} from 'reactfire';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import moment from 'moment';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const { Option } = Select;

const Transactions = (props) => {
  const firestore = useFirestore();
  const settingsData = useFirestore().collection('settings');
  const { status: settingsLoading, data: settings } =
    useFirestoreCollectionData(settingsData);

  const getFreelancers = useFirestore()
    .collection('users')
    .where('freelancer', '==', true);
  const { status: freelancersStatus, data: freelancers } =
    useFirestoreCollectionData(getFreelancers);

  const getOrders = useFirestore().collection('orders');
  const { status: ordersStatus, data: orders } =
    useFirestoreCollectionData(getOrders);

  const [freelancersBalance, setFreelancersBalance] = useState(0);
  const [ordersBalance, setOrdersBalance] = useState(0);
  const [adminBalance, setAdminBalance] = useState(0);

  useEffect(() => {
    calculateFreelancersBalance();
    calculateTotalOrders();
    calculateAdminBalance();
  }, []);

  const calculateFreelancersBalance = (week = false) => {
    const today = moment();
    let weekStart = today.clone().startOf('isoWeek');
    let weekEnd = today.clone().endOf('isoWeek');

    if (week) {
      let weekly = orders.reduce((results, oritem) => {
        if (moment(oritem.createdAt).isBetween(weekStart, weekEnd)) {
          results.push(oritem);
        }
        return results;
      }, []);

      let total = weekly.reduce(
        (accumulator, current) => accumulator + current.freelancerBalance,
        0
      );
      setFreelancersBalance(total);
      return true;
    }
    let total = orders.reduce(
      (accumulator, current) => accumulator + current.freelancerBalance,
      0
    );
    setFreelancersBalance(total);
  };

  const calculateTotalOrders = (week = false) => {
    const today = moment();
    let weekStart = today.clone().startOf('isoWeek');
    let weekEnd = today.clone().endOf('isoWeek');

    if (week) {
      let weekly = orders.reduce((results, oritem) => {
        if (moment(oritem.createdAt).isBetween(weekStart, weekEnd)) {
          results.push(oritem);
        }
        return results;
      }, []);

      let total = weekly.reduce(
        (accumulator, current) => accumulator + current.total,
        0
      );
      setOrdersBalance(total);
      return true;
    }
    let total = orders.reduce(
      (accumulator, current) => accumulator + current.total,
      0
    );
    setOrdersBalance(total);
  };

  const calculateAdminBalance = (week = false) => {
    const today = moment();
    let weekStart = today.clone().startOf('isoWeek');
    let weekEnd = today.clone().endOf('isoWeek');

    if (week) {
      let weekly = orders.reduce((results, oritem) => {
        if (moment(oritem.createdAt).isBetween(weekStart, weekEnd)) {
          results.push(oritem);
        }
        return results;
      }, []);

      let total = weekly.reduce(
        (accumulator, current) => accumulator + current.adminBalance,
        0
      );
      setAdminBalance(total);
      return true;
    }
    let total = orders.reduce(
      (accumulator, current) => accumulator + current.adminBalance,
      0
    );
    setAdminBalance(total);
  };

  return (
    <>
      <Divider>Transaction Total</Divider>
      <Row gutter={[8, 16]} justify='center' style={{ marginTop: 50 }}>
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <Select
            defaultValue='full'
            style={{ width: '100%' }}
            onChange={(key) => {
              console.log('log-key', key);
              if (key === 'full') {
                calculateFreelancersBalance();
                calculateTotalOrders();
                calculateAdminBalance();
              } else {
                calculateFreelancersBalance(true);
                calculateTotalOrders(true);
                calculateAdminBalance(true);
              }
            }}
          >
            <Option value='full'>Full</Option>
            <Option value='week'>Last 7 Days</Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[8, 16]} justify='center'>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card className='service-card'>
            <h3 className='text-center'>Freelancers Balance</h3>
            <h4 className='text-center'>
              {freelancersBalance > 0 ? freelancersBalance : 0} CAD
            </h4>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card className='service-card'>
            <h3 className='text-center'>Total Order</h3>
            <h4 className='text-center'>
              {ordersBalance > 0 ? ordersBalance : 0} CAD
            </h4>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card className='service-card'>
            <h3 className='text-center'>Admin Balance</h3>
            <h4 className='text-center'>{adminBalance} CAD</h4>
          </Card>
        </Col>
      </Row>
      <Row gutter={[8, 16]} justify='center' style={{ marginTop: 50 }}>
        <Divider>Other Settings</Divider>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card className='service-card'>
            <Form
              {...layout}
              initialValues={{
                commissionRate: settings ? settings[0].commissionRate : 0,
              }}
              onFinish={(values) => {
                firestore
                  .collection('settings')
                  .doc(settings.docs[0].id)
                  .set(values, { merge: true })
                  .then((r) => {
                    Modal.success({
                      title: 'Awesome!',
                      content: 'Settings successfully updated.',
                    });
                  });
              }}
            >
              <Form.Item label='Commission Rate' name='commissionRate'>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => `${value}%`}
                />
              </Form.Item>
              <div className='service-card-footer'>
                <Button type='primary' htmlType='submit'>
                  Save Settings
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Transactions;
