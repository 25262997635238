import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAckx_4DfxBEiqrMpO_OCEQCYUIflJ-8o",
  authDomain: "nailzy-app.firebaseapp.com",
  databaseURL: "https://nailzy-app-default-rtdb.firebaseio.com",
  projectId: "nailzy-app",
  storageBucket: "nailzy-app.appspot.com",
  messagingSenderId: "116034601653",
  appId: "1:116034601653:web:fd5e9e50161adae16234fc",
  measurementId: "G-X2EQ1CW0NR"
};

export default firebaseConfig;
